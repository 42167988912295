import React from 'react';
import MaintenanceCustom from '@views/Maintenance/MaintenanceCustom'
import { toast } from 'react-toastify'
import Helpers from '@components/Helpers/Helpers';
import Controllers from '@components/Controllers/Controllers';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/esm/Card';
import Button from 'react-bootstrap/esm/Button';
import { CheckBox } from '@components/Form/Form'

const Profile = ({ showFirstLevel }) => {
    return <>
        <MaintenanceCustom
            title="Roles" namePlural="Roles" modalSize="lg" nameSingle="Rol" separator="el" module="profile"
            actionsCustom
            actions={({ updateButton, deleteButton, data }) => {
                if (parseInt(data.id_profile) === 1) {
                    return ''
                }
                return <>
                    {updateButton}
                    {deleteButton}
                </>
            }}
            optionsView={{
                dataStart: [
                    { name: 'main_profile', value: '' },
                ],
                beforeRenderForm: ({ data, setStateDynamic, ready }) => {
                    Controllers.profile.get_profile_tags_items({
                        p_id_profile: isNaN(parseInt(data.id_profile)) ? '' : data.id_profile
                    }).then(res => {
                        setStateDynamic({
                            childrens: res.data
                        })
                    })
                    ready()
                },
                append: ({ stateDynamic, setStateDynamic }) => {
                    return <>
                        <div className="col-12 mb-2">
                            <strong className="text-primary">Listado de Privilegios</strong>
                        </div>
                        <div className="col-12">
                            {stateDynamic?.childrens ? React.Children.toArray(stateDynamic.childrens.map((tpm, i) => {
                                return <div className={i + 1 < stateDynamic.childrens.length ? 'mb-3' : ''}>
                                    {showFirstLevel ? <div>{tpm.parent.description}</div> : ''}
                                    {React.Children.toArray(tpm.childrens.map((tp, j) => {
                                        return <>
                                            <div className={showFirstLevel ? 'ml-3' : ''}>{tp.parent.description}</div>
                                            {React.Children.toArray(tp.childrens.map((ch, k) => {
                                                return <div className={showFirstLevel ? 'ml-5' : 'ml-3'}>
                                                    <div className="d-flex">
                                                        <CheckBox
                                                            classNameParent=""
                                                            checked={parseInt(ch.enabled) === 1}
                                                            text={ch.description}
                                                            id={`tags-items-check-${ch.id_tags_items_parent_master}-${ch.id_tags_items_parent}-${ch.id_tags_items}`}
                                                            onChange={e => {
                                                                setStateDynamic({
                                                                    ...stateDynamic,
                                                                    p_childrens: stateDynamic.childrens.map((tpm2, i2) => {
                                                                        if (i === i2) {
                                                                            tpm2.childrens = tpm2.childrens.map((tp2, j2) => {
                                                                                if (j === j2) {
                                                                                    tp2.childrens = tp2.childrens.map((ch2, k2) => {
                                                                                        if (k === k2) {
                                                                                            ch2.enabled = e.currentTarget.checked ? 1 : 0
                                                                                            if (ch2.enabled === 0) {
                                                                                                ch2.main = 0
                                                                                            }
                                                                                        }
                                                                                        return ch2
                                                                                    })
                                                                                    return tp2
                                                                                }
                                                                            })
                                                                        }
                                                                        return tpm2
                                                                    })
                                                                })
                                                            }}
                                                        />
                                                        <div className="align-self-center">
                                                            <i className={`${parseInt(ch.main) === 1 ? 'fas fa-star text-primary' : 'far fa-star'} cur-pointer ml-2`} onClick={_ => {
                                                                setStateDynamic({
                                                                    ...stateDynamic,
                                                                    p_childrens: stateDynamic.childrens.map((tpm2, i2) => {
                                                                        tpm2.childrens = tpm2.childrens.map((tp2, j2) => {
                                                                            tp2.childrens = tp2.childrens.map((ch2, k2) => {
                                                                                ch2.main = 0
                                                                                if (i === i2 && j === j2 && k === k2) {
                                                                                    ch2.main = parseInt(ch2.main) === 1 ? 0 : 1
                                                                                    if (parseInt(ch2.main) === 1) {
                                                                                        ch2.enabled = 1
                                                                                    }
                                                                                }
                                                                                return ch2
                                                                            })
                                                                            return tp2
                                                                        })
                                                                        return tpm2
                                                                    })
                                                                })
                                                            }}></i>{parseInt(ch.main) === 1 ? <small> (Módulo Principal *)</small> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            }))}
                                        </>
                                    }))}
                                </div>
                            })) : ''}
                        </div>
                    </>
                },
                appendAfterForm: _ => {
                    return <small>* Módulo principal: módulo inicial cuando un usuario inicia sesión en el sistema.</small>
                },
                submitCustom: true,
                submitIsCorrect: ({ data, stateDynamic }) => {
                    let childrens = []
                    let main_profile = ''
                    stateDynamic.childrens.forEach(tpm => {
                        tpm.childrens.forEach(tp => {
                            tp.childrens.forEach(t => {
                                childrens.push(t)
                                if (parseInt(t.main) === 1) {
                                    main_profile = t.a_href
                                }
                            })
                        })
                    })
                    data.p_childrens = childrens

                    if (!main_profile) {
                        Helpers.toast.warning({ message: 'Selecionar módulo principal', toast })
                        return false
                    }
                    data.p_main_profile = main_profile

                    return data
                },
            }}
        />
    </>
}

Profile.defaultProps = {
    showFirstLevel: true
}

export default Profile;