import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'

const styles = {
  groupStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  groupBadgeStyles: {
    backgroundColor: 'red',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  },
  styles: {
    container: base => ({
      ...base,
      flex: 1
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: '29.55px',
      height: '29.55px',
      borderColor: '#e74c3c',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
      marginTop: '-1px'
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      maxHeight: '29.55px'
    }),
  }
}

const styleSizeNormal = {
  styles: {
    container: base => ({
      ...base,
      flex: 1
    }),
    control: (provided) => ({
      ...provided,
      minHeight: '38px',
      height: '38px',
      borderColor: '1px solid red'
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
      marginTop: '-1px'
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      maxHeight: '38px'
    }),
  }
}

const generateStyles = ({ size = '', show = false, required = false } = {}) => {
  return {
    container: base => ({
      ...base,
      flex: 1
    }),
    control: (provided, state) => {
      return ({
        ...provided,
        ...(size === '' ? {
          minHeight: '38px',
          height: '38px',
        } : {
          minHeight: '29.55px',
          height: '29.55px',
        }),
        ...(show && required && !state.hasValue ? {
          borderColor: '#e74c3c'
        } : (show ? {
          borderColor: '#37af47'
        } : {}))
      })
    },
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
      marginTop: '-1px'
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      maxHeight: '29.55px'
    }),
  }
}

const Select = ({ classNameParent, text, size, icon, onChange, value, defaultValue, className, options, append, disabled, smallColor, required, invalid, showFeedback, ...props }) => {
  const propValue = useCallback(() => {
    return defaultValue ? { defaultValue } : { value: typeof value === 'function' ? value() : value }
  }, [defaultValue, value])
 
  const [optionsList, setOptionsList] = useState([])

  useEffect(_ => {
    setOptionsList(typeof options === 'function' ? options() : options)
  }, [options])

  return (<div className={classNameParent}>
    <small className={smallColor}>{text}</small>
    <div className={'input-group input-group-' + size}>
      {icon ? <div className="input-group-prepend">
        <div className="input-group-text"><i className={icon}></i></div>
      </div> : ''}
      <ReactSelect
        styles={generateStyles({
          size,
          show: showFeedback,
          required
        })}
        options={optionsList}
        {...propValue()}
        onChange={e => onChange(e)}
        formatGroupLabel={data => {
          return <div style={styles.groupStyles}>
            <span>{data.label}</span>
            <span style={styles.groupBadgeStyles}>{data.options.length}</span>
          </div>
        }}
        placeholder="Seleccionar..."
        {...props}
        isDisabled={disabled}
      />
      <div className="input-group-append">
        {React.Children.toArray(append)}
      </div>
      <div style={{
        width: '100%',
        marginTop: '0.25rem',
        fontSize: '80%',
        color: (propValue().value || propValue().defaultValue) ? '#37af47' : '#e74c3c'
      }}>
        {showFeedback && required && !(propValue().value || propValue().defaultValue) ? invalid : ''}
      </div>
    </div>
  </div>)
}

Select.defaultProps = {
  size: 'sm',
  icon: '',
  classNameParent: 'col-12',
  required: false,
  append: [],
  options: [],
  onChange: () => {},
  disabled: false,
  smallColor: 'text-muted',
  invalid: 'Campo obligatorio',
  showFeedback: false
}
Select.propTypes = {
  classNameParent: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg', '']),
  icon: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  append: PropTypes.array,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.func]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  required: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

export default Select