import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import {Input, SwitchToggle} from '@components/Form/Form'

const TagsItemsChildren = ({ children, idx, idxParent, changeDescriptionChildren, changeHrefChildren, changeEnabledChildren, changeShowChildren, deleteChildren }) => {
    return <>
        <Card className="card-with-border">
            <Card.Header className="justify-content-between">
                <Accordion.Toggle as={Button} variant="link" eventKey={idx}>
                    {children.description.trim() ? children.description : <div className="text text-warning">Sin Nombre</div>}
                </Accordion.Toggle>
                <Button variant="danger" size="sm" onClick={deleteChildren}>
                    <i className="fa fa-trash"></i>
                </Button>
            </Card.Header>
            <Accordion.Collapse eventKey={idx}>
                <Card.Body>
                    <div className="form-row">
                        <Input
                            type="text"
                            text="Descripción"
                            placeholder="Descripción"
                            classNameParent="col-md-6 mb-2"
                            icon="far fa-comment-alt"
                            value={children.description}
                            onChange={changeDescriptionChildren}
                            maxLength={100}
                        />
                        <Input
                            type="text"
                            text="Link"
                            placeholder="Link"
                            classNameParent="col-md-6 mb-2"
                            icon="far fa-comment-alt"
                            value={children.a_href}
                            onChange={changeHrefChildren}
                            maxLength={100}
                        />
                        <SwitchToggle
                            classNameParent="col-12 mb-2"
                            id={`enabled-parent-${idxParent}-children-${idx}`}
                            checked={parseInt(children.enabled) === 1}
                            onChange={changeEnabledChildren}
                        />
                        <SwitchToggle
                            classNameParent="col-12"
                            id={`show-parent-${idxParent}-children-${idx}`}
                            textCheck="Ocultar"
                            textUnChecked="Mostrar"
                            checked={parseInt(children.show) === 1}
                            onChange={changeShowChildren}
                        />
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    </>
}

export default TagsItemsChildren