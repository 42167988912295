import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'

const SwitchToggle = ({ defaultChecked, onChange, classNameParent, textCheck, textUnChecked, ...props }) => {
  const [checked, setChecked] = useState(defaultChecked)

  const handleChangeChecked = e => {
    onChange(e)
    setChecked(!checked)
  }

  return <div className={classNameParent}>
    <Form.Check
      {...props}
      defaultChecked={checked}
      type="switch"
      label={checked ? textCheck : textUnChecked}
      onChange={handleChangeChecked}
      value={checked ? 1 : 0}
    />
  </div>
}
SwitchToggle.defaultProps = {
  disabled: false,
  classNameParent: 'col-12',
  onChange: () => {},
  required: false,
  textCheck: 'Desactivar',
  textUnChecked: 'Activar'
}
SwitchToggle.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  classNameParent: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  required: PropTypes.bool
}
export default SwitchToggle