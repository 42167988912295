import React from 'react';
import MaintenanceCustom from '@views/Maintenance/MaintenanceCustom'
import { toast } from 'react-toastify'
import Helpers from '@components/Helpers/Helpers';
import Controllers from '@components/Controllers/Controllers';
import Accordion from 'react-bootstrap/Accordion'
import TagsItemsParent from '@views/TagsItems/TagsItemsParent';
import Card from 'react-bootstrap/esm/Card';
import Button from 'react-bootstrap/esm/Button';

const TagsItems = _ => {
    const changeParentChildren = ({ setRow, row, idxParent, idxChildren = -1, field, value }) => {
        setRow({
            ...row,
            p_childrens: row.p_childrens.map((p, i) => {
                if (idxParent === i) {
                    if (idxChildren === -1) {
                        p.parent[field] = value
                    } else {
                        p.childrens = p.childrens.map((ch, j) => {
                            if (idxChildren === j) {
                                ch[field] = value
                            }

                            return ch
                        })
                    }
                }
    
                return p
            })
        })
    }

    const addParent = ({ row, setRow }) => {
        setRow({
            ...row,
            p_childrens: [
                ...(row?.p_childrens ? row.p_childrens : []),
                {
                    parent: {
                        description: '',
                        enabled: 0,
                        icon: '',
                        id_tags_items: '',
                        id_tags_items_parent: '',
                        id_tags_items_parent_master: '',
                    },
                    childrens: []
                }
            ]
        })
    }

    const addChildren = ({ row, setRow, idxParent }) => {
        setRow({
            ...row,
            p_childrens: row.p_childrens.map((ch, i) => {
                if (i === idxParent) {
                    ch.childrens = [
                        ...ch.childrens,
                        {
                            description: '',
                            enabled: 0,
                            a_href: '',
                            show: 0,
                            id_tags_items: '',
                            id_tags_items_parent: '',
                            id_tags_items_parent_master: '',
                        }
                    ]
                }

                return ch
            })
        })
    }

    const deleteParent = ({ row, setRow, idxParent, exec, idTagsItemsParent }) => {
        let promises = []
        if (exec) {
            promises.push(Controllers.tags_items.tags_items_delete({
                p_id_tags_items: idTagsItemsParent,
                p_type: 'id_tags_items_parent'
            }))
        }

        Promise.all(promises).then(res => {
            let deleteItem = true

            if (res.length > 0) {
                if (res[0].response !== 'success') {
                    Helpers.toast.construct({ ...res[0], toast })
                    deleteItem = false
                }
            }

            if (deleteItem) {
                setRow({
                    ...row,
                    p_childrens: row.p_childrens.filter((p, i) => {
                        return i !== idxParent
                    })
                })
            }
        })
    }

    const deleteChildren = ({ row, setRow, idxParent, idxChildren, exec, idTagsItems }) => {
        let promises = []
        if (exec) {
            promises.push(Controllers.tags_items.tags_items_delete({
                p_id_tags_items: idTagsItems,
                p_type: 'id_tags_items'
            }))
        }

        Promise.all(promises).then(res => {
            let deleteItem = true

            if (res.length > 0) {
                if (res[0].response !== 'success') {
                    Helpers.toast.construct({ ...res[0], toast })
                    deleteItem = false
                }
            }

            if (deleteItem) {
                setRow({
                    ...row,
                    p_childrens: row.p_childrens.map((p, i) => {
                        if (i === idxParent) {
                            p.childrens = p.childrens.filter((ch, j) => {
                                return j !== idxChildren
                            })
                        }
        
                        return p
                    })
                })
            }
        })
    }

    return <>
        <MaintenanceCustom
        title="Privilegios" namePlural="Privilegios" modalSize="lg" nameSingle="Privilegio" separator="el" module="tags_items"
            tableFieldsAdd={[
                {
                    name: 'number', text: 'Orden', align: 'center', hideOnForm: true
                },
            ]}
            optionsView={{
                dataStart: [
                    {name: 'number', value : ''},
                    {name: 'childrens', value : []},
                ],
                append: ({ row, setRow }) => {
                    return <div className="col-12">
                        <Card>
                            <Card.Header className="card-with-border justify-content-between">
                                <span>Listado de Items</span>
                                <Button size="sm" onClick={_ => {
                                    addParent({ row, setRow })
                                }}>
                                    <i className="fa fa-plus"></i>
                                </Button>
                            </Card.Header>
                        </Card>
                        {row?.p_childrens ? <Accordion defaultActiveKey={0}>
                            {React.Children.toArray(row.p_childrens.map((p, i) => {
                                return <>
                                    <TagsItemsParent
                                        addChildren={_ => {
                                            addChildren({ row, setRow, idxParent: i })
                                        }}
                                        deleteParent={_ => {
                                            deleteParent({ row, setRow, idxParent: i, exec: p.parent.description !== '', idTagsItemsParent: p.parent.id_tags_items_parent })
                                        }}
                                        deleteChildren={({ idxChildren, idTagsItems, exec }) => {
                                            deleteChildren({ row, setRow, idxParent: i, idxChildren, idTagsItems, exec })
                                        }}
                                        parent={p} idx={i + 1}
                                        changeDescriptionParent={e => {
                                            changeParentChildren({e, row, setRow, idxParent: i, field: 'description', value: e.currentTarget.value })
                                        }}
                                        changeIconParent={e => {
                                            changeParentChildren({e, row, setRow, idxParent: i, field: 'icon', value: e.currentTarget.value })
                                        }}
                                        changeEnabledParent={e => {
                                            changeParentChildren({e, row, setRow, idxParent: i, field: 'enabled', value: e.currentTarget.checked ? 1 : 0 })
                                        }}
                                        changeDescriptionChildren={({ e, idxChildren }) => {
                                            changeParentChildren({e, row, setRow, idxParent: i, idxChildren, field: 'description', value: e.currentTarget.value })
                                        }}
                                        changeHrefChildren={({ e, idxChildren }) => {
                                            changeParentChildren({e, row, setRow, idxParent: i, idxChildren, field: 'a_href', value: e.currentTarget.value })
                                        }}
                                        changeEnabledChildren={({ e, idxChildren }) => {
                                            changeParentChildren({e, row, setRow, idxParent: i, idxChildren, field: 'enabled', value: e.currentTarget.checked ? 1 : 0 })
                                        }}
                                        changeShowChildren={({ e, idxChildren }) => {
                                            changeParentChildren({e, row, setRow, idxParent: i, idxChildren, field: 'show', value: e.currentTarget.checked ? 1 : 0 })
                                        }}
                                    />
                                </>
                            }))}
                        </Accordion> : ''}
                    </div>
                },
                submitCustom: true,
                submitIsCorrect: ({ data, stateDynamic }) => {
                    if (!data?.p_childrens) {
                        Helpers.toast.construct({ response: 'warning', message: 'Agregar submenú', toast })
                        return false
                    }

                    let hasError = false
                    data.p_childrens.forEach(p => {
                        if (!hasError && (p.parent.description.trim() === '' || p.parent.icon.trim() === '')) {
                            hasError = true
                        }
                        if (!hasError && p.childrens.length === 0) {
                            hasError = true
                        } else {
                            p.childrens.forEach(ch => {
                                if (!hasError && (ch.description.trim() === '' || ch.a_href.trim() === '')) {
                                    hasError = true
                                }
                            })
                        }
                    })

                    if (hasError) {
                        Helpers.toast.construct({ response: 'warning', message: 'Completar la información', toast })
                        return false
                    }

                    return data
                },
            }}
        />
    </>
}

export default TagsItems;