import React, { useState, useEffect } from 'react';
import { Preloading } from 'react-theme-bootstrap'
import Helpers from '@components/Helpers/Helpers'
import axios from 'axios'
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { setSession } from './Redux/Actions'
import Store from '@redux/Store';
import Routes from './Routes'

const App = () => {
    const [content, setContent] = useState(<Preloading
        image={Helpers.config.resourcesUrl({ url: '/images/dashboard/preloading.gif' })}
    />);

    useEffect(() => {
        axios({
            url: Helpers.config.apiUrl({ url: '/users/session' }),
            method: 'POST',
            data: {
                jwt: Helpers.localStorage.getJWT()
            }
        }).then(res => {
            return res.data;
        }).then(res => {
            if (res.message === Helpers.config.sessionError()) {
                localStorage.clear();
                setContent(<Provider store={Store}>
                    <Routes type="auth" />
                    <ToastContainer />
                </Provider>);
            } else {
                Store.dispatch(setSession(res.data));
                Helpers.localStorage.setSession({
                    jwt: res.data.jwt
                });
                setContent(<Provider store={Store}>
                    <Routes />
                    <ToastContainer />
                </Provider>);
            }
        }).catch(req => {
            console.error(req);
        });
    }, []);

    return content;
}

export default App