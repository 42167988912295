import React from 'react';
import MaintenanceCustom from '@views/Maintenance/MaintenanceCustom'
import { toast } from 'react-toastify'
import Helpers from '@components/Helpers/Helpers';
import Controllers from '@components/Controllers/Controllers';
import { SwitchToggle, Input, Select, Radio } from '@components/Form/Form';
import Button from 'react-bootstrap/Button'
import Customers from '@views/Customers/Customers'

/**
 * Sale
 * @version 0.9.92
 */
const Sale = _ => {

    /**
     * addProduct
     * @version 0.7.5
     * @param {*} param0
     */
    const addProduct = ({ stateDynamic, setStateDynamic, product }) => {

        let discountPrice;

        if (product.discount_type_document_det == 'SIN_DESCUENTO') {
            discountPrice = product.price_product;
        } else if (product.discount_type_document_det == 'PRICE') {
            discountPrice = product.price_product - product.discount_document_det;
        } else if (product.discount_type_document_det == 'PERCENTAGE') {
            discountPrice = product.price_product - (product.price_product * (product.discount_document_det / 100));
        }

        setStateDynamic({
            ...stateDynamic,
            products: [
                ...stateDynamic.products,
                {
                    ...product,
                    subtotal: 0,
                    id_document_det: '',
                    price_document_det: product.price_product,
                    discount_type_document_det: product.discount_type_document_det,
                    qty_document_det: 1,
                    discount_document_det: product.discount_document_det,
                    discount_price_document_det: discountPrice,
                    id_product: product.id_product
                }
            ],
        })
    }
    /**
     * calculateDiscount
     * @version 0.4.5
     * @param {*} param0 
     * @returns 
     */
    const calculateDiscount = ({ active_discount_dates_product, discount_type_product, discount_percentage_product, discount_price_product, price_document_det, qty_document_det, discount_type_document_det, discount_document_det }) => {
        let discount = 0
        if (parseInt(active_discount_dates_product) === 1) {
            if (discount_type_product === 'PRICE') {
                discount += parseFloat(discount_price_product)
            } else if (discount_type_product === 'PERCENTAGE') {
                discount += (parseFloat(discount_percentage_product) * parseFloat(price_document_det))
            }
        } else {
            if (discount_type_product === 'PRICE') {
                discount += parseFloat(discount_price_product)
            } else if (discount_type_product === 'PERCENTAGE') {
                discount += (parseFloat(discount_percentage_product / 100) * parseFloat(price_document_det))
            }
        }

        if (discount_type_document_det == 'PRICE') {
            discount += parseFloat(discount_document_det) / qty_document_det
        } else if (discount_type_document_det == 'PERCENTAGE') {
            // discount += parseFloat(qty_document_det * (price_document_det * (discount_document_det / 100)))
            discount += parseFloat(discount_document_det / 100) * parseFloat(price_document_det)
        }

        return discount * (isNaN(parseInt(qty_document_det)) ? 1 : parseInt(qty_document_det) === 0 ? 1 : parseInt(qty_document_det))
    }
    /**
     * calculateSubtotal
     * @version 0.4.5
     * @param {*} param0 
     * @returns 
     */
    const calculateSubtotal = ({ stateDynamic, row }) => {
        let total = 0, subtotal = 0, iva = 0, importe = 0, discountAdd = 0

        if (stateDynamic?.products) {
            stateDynamic.products.forEach(p => {
                let qtySale = (isNaN(parseInt(p.qty_document_det)) ? 1 : parseInt(p.qty_document_det) === 0 ? 1 : parseInt(p.qty_document_det))
                importe += (qtySale * parseFloat(p.price_document_det)) - calculateDiscount(p)
                discountAdd += calculateDiscount(p)
            })
        }


        // if (row.p_discount_type_add_document === 'PRICE') {
        //     discountAdd = row.p_discount_add_document
        // } else if (row.p_discount_type_add_document === 'PERCENTAGE') {
        //     discountAdd = (row.p_discount_add_document / 100) * importe
        // }

        total = importe
        iva = ((total / 1.16) - total) * -1
        subtotal = total - iva

        return {
            subtotal,
            total,
            discountAdd: discountAdd,
            iva,
            import: importe
        }
    }

    /**
     * searchUserPassword
     * @version 0.4.5
     * @param {*} param
     * @returns 
     */
    const searchUserPassword = ({ stateDynamic, setStateDynamic, setRow, row, store, setExecValidation }) => {
        let txtAmountDiscountAdd = document.getElementById('txt-amount-discount-add')
        let txtPasswordUser = document.getElementById('txt-password-user')
        // if (isNaN(parseFloat(stateDynamic.discountAdd))) {
        //     Helpers.toast.warning({
        //         message: 'Ingresar monto correcto',
        //         toast
        //     })
        //     txtAmountDiscountAdd.focus()
        //     return
        // }

        // if (parseFloat(stateDynamic.discountAdd) <= 0) {
        //     Helpers.toast.warning({
        //         message: 'Ingresar monto correcto',
        //         toast
        //     })
        //     txtAmountDiscountAdd.focus()
        //     return
        // }

        if (!txtPasswordUser.value) {
            Helpers.toast.warning({
                message: 'Ingresar Contraseña',
                toast
            })
            txtPasswordUser.focus()
            return
        }

        // setStateDynamic({
        //     ...stateDynamic,
        //     loadPassword: true
        // })

        Controllers.users.get_user_password({
            p_password: stateDynamic.passwordUser
        }).then(res => {
            if (isNaN(parseInt(res.data?.id_user))) {
                Helpers.toast.warning({
                    message: 'Contraseña incorrecta',
                    toast
                })
                setStateDynamic({
                    ...stateDynamic,
                    passwordUser: '',
                    loadPassword: false
                })
                txtPasswordUser.focus()
                return
            }

            setRow({
                ...row,
                p_discount_add_document: parseFloat(stateDynamic.discountAdd),
                p_user_request_discount_document: store.session.username,
                p_user_approval_discount_document: res.data.username_user,
            })
            setStateDynamic({
                ...stateDynamic,
                editDiscount: false,
                discountAdd: 0,
                passwordUser: '',
                loadPassword: true,

            })
            setExecValidation(true)
        })
    }

    return <>
        <MaintenanceCustom
            afterInsertUpdate={({ setModalBodyAdd, setModalShowAdd, res }) => {
                setModalBodyAdd(<iframe style={{
                    width: '100%', minHeight: '500px'
                }} src={Helpers.config.apiUrl({ url: '/document/ticket/' + res.data.id })}></iframe>)
                setModalShowAdd(true)
            }}
            title={<>
                <i className="fa fa-list text-secondary mr-2"></i>Ventas
            </>} namePlural="Ventas" nameSingle="Venta" separator="el" module="document"
            tableFieldsAdd={[
                {
                    name: 'description_warehouse', align: 'center', text: 'Almacen', render: r => r.description_warehouse,
                    hideOnForm: true
                },
                {
                    name: 'id_customers', align: 'center', text: 'Cliente', render: r => {
                        return `${r.first_name ? r.first_name + ' ' + r.last_name : ''}`
                    },
                    hideOnForm: true
                },
                {
                    name: 'total_document', align: 'center', text: 'Total Venta', render: r => {
                        return `$ ${Helpers.number.withCommas({ number: r.total_document })}`
                    },
                    hideOnForm: true
                },
                {
                    name: 'date_document', align: 'center', text: 'Fecha Venta',
                    hideOnForm: true
                },
                {
                    name: 'id_method_pay', align: 'center', text: 'Método Pago', render: r => r.description_method_pay,
                    hideOnForm: true
                },
                {
                    name: 'id_format_pay', align: 'center', text: 'Forma Pago', render: r => r.description_format_pay,
                    hideOnForm: true
                },
            ]}
            optionsEnabled={{
                render: r => {
                    return Helpers.components.state({
                        state: parseInt(r.enabled_document),
                        textOne: 'VENTA',
                        textZero: 'ANULADO',
                    })
                }
            }}
            optionsView={{
                removeDescription: true,
                dataStart: [
                    { name: 'discount_add_document', value: 0 },
                    { name: 'total_document', value: '' },
                    { name: 'subtotal_document', value: '' },
                    { name: 'iva_document', value: '' },
                    { name: 'discount_document', value: '' },
                    { name: 'discount_add_document', value: '' },
                    { name: 'user_request_discount_document', value: '' },
                    { name: 'user_approval_discount_document', value: '' },
                    { name: 'type_document', value: '' },
                    { name: 'serie_document', value: '' },
                    { name: 'invoice_document', value: '' },
                    { name: 'date_document', value: Helpers.date.get({ format: '%Y-%m-%d' }) },
                    { name: 'observation_document', value: '' },
                    { name: 'condition_pay', value: '' },
                    { name: 'id_format_pay', value: '' },
                    { name: 'id_method_pay', value: '' },
                    { name: 'id_cdfi', value: '' },
                    { name: 'user_request_discount_document', value: '' },
                    { name: 'user_approval_discount_document', value: '' },
                    { name: 'discount_type_add_document', value: 'SIN_DESCUENTO' },
                    { name: 'id_customers', value: '' },
                    { name: 'enabled_document', value: 1 },
                ],
                beforeRenderForm: ({ ready, data, setState, setStateDynamic }) => {
                    let promises = [
                        Controllers.product.get_product(),
                        Controllers.customers.get_customers(),
                        Controllers.format_pay.get_format_pay(),
                        Controllers.method_pay.get_method_pay(),
                        Controllers.cdfi.get_cdfi()
                    ]

                    if (!isNaN(data.id_document)) {
                        promises.push(Controllers.document_det.get_document_det({
                            p_id_document: data.id_document
                        }))
                    }

                    if (!isNaN(parseInt(data.id_customers))) {
                        promises.push(Controllers.customers.get_customers_id({
                            p_id_customers: data.id_customers
                        }))
                    }

                    Promise.all(promises).then(res => {
                        setState({
                            product: res[0].data,
                            customers: res[1].data,
                            format_pay: res[2].data,
                            method_pay: res[3].data,
                            cdfi: res[4].data,
                        })
                        setStateDynamic({
                            products: !isNaN(data.id_document) ? res[5].data : [],
                            editDiscount: false,
                            discountAdd: 0,
                            passwordUser: '',
                            loadPassword: false,
                            customer: !isNaN(parseInt(data.id_customers)) ? res[6].data : {},
                            isReadOnly: !isNaN(data.id_document)
                        })
                        ready()
                    })
                },
                removeEnabled: true,
                submitCustom: true,
                submitIsCorrect: ({ data, stateDynamic }) => {
                    if (parseInt(stateDynamic.customer?.id_customers) === 0) {
                        Helpers.toast.warning({ message: 'Seleccionar Cliente', toast })
                        return false
                    }

                    if (!data.p_id_format_pay) {
                        Helpers.toast.warning({ message: 'Ingresar forma de pago', toast })
                        return false
                    }

                    if (!data.p_id_method_pay) {
                        Helpers.toast.warning({ message: 'Ingresar método de pago', toast })
                        return false
                    }

                    if (parseInt(stateDynamic.customer?.id_customers) >= 0) {
                        if (!data.p_id_cdfi) {
                            Helpers.toast.warning({ message: 'Ingresar uso CFDI', toast })
                            return false
                        }
                    }

                    if (stateDynamic.products.length === 0) {
                        Helpers.toast.warning({ message: 'Agregar productos a la venta', toast })
                        return false
                    }

                    let subtotales = calculateSubtotal({ stateDynamic, row: data })
                    data.p_total_document = subtotales.total
                    data.p_subtotal_document = subtotales.subtotal
                    data.p_iva_document = subtotales.iva
                    data.p_discount_document = 0
                    data.p_discount_add_document = subtotales.discountAdd
                    data.p_list_document_det = stateDynamic.products.map(p => {
                        return {
                            id_document_det: p.id_document_det,
                            id_product: p.id_product,
                            qty_document_det: p.qty_document_det,
                            price_document_det: p.price_document_det,
                            discount_type_document_det: (p.discount_type_document_det === undefined) ? 'SIN_DESCUENTO' : p.discount_type_document_det,
                            discount_document_det: (p.discount_type_document_det === undefined) ? 0 : p.discount_document_det,
                            discount_price_document_det: p.discount_price_document_det,
                        }
                    })
                    return data
                },
                appendAfterForm: ({ state, setState }) => {
                    return <div className="d-none">
                        <Customers onlyButton btnNewOptions={{
                            id: 'button_show_customers',
                            variant: 'primary'
                        }} afterInsertUpdate={_ => {
                            Controllers.customers.get_customers().then(res => {
                                setState({ ...state, customers: res.data })
                            })
                        }} />
                    </div>
                },
                append: ({ row, setRow, state, stateDynamic, setStateDynamic, renderSelect, renderInput, renderTextArea, setExecValidation, store, data }) => {
                    let subtotales = calculateSubtotal({ stateDynamic, row })
                    return <>
                        <div class="col-sm-12">
                            <div class="row">
                                <SwitchToggle
                                    id="switch-enabled-customers"
                                    textUnChecked="Agregar Factura"
                                    textCheck="Quitar Factura"
                                    checked={parseInt(stateDynamic.customer?.id_customers) >= 0}
                                    onChange={e => {
                                        setStateDynamic({
                                            ...stateDynamic,
                                            customer: {
                                                id_customers: e.currentTarget.checked ? 0 : '',
                                            }
                                        })
                                        if (!e.currentTarget.checked) {
                                            setRow({
                                                ...row,
                                                p_id_customers: '',
                                                p_condition_pay_document: '',
                                                p_id_format_pay: '',
                                                p_id_method_pay: '',
                                                p_id_cdfi: '',
                                            })
                                        }
                                    }}
                                    classNameParent="col-sm-3"
                                    disabled={stateDynamic.isReadOnly && !stateDynamic.loadPassword}
                                />
                                <div class="d-flex">
                                    <Input
                                        classNameParent="w-100"
                                        style={{ minWidth: 150 }}
                                        type="password"
                                        size=""
                                        placeholder="Clave del Gerente"
                                        value={stateDynamic.passwordUser}
                                        onChange={e => setStateDynamic({
                                            ...stateDynamic,
                                            passwordUser: e.currentTarget.value
                                        })}
                                        onFocus={_ => setExecValidation(false)}
                                        onBlur={_ => setExecValidation(true)}
                                        autoComplete="new-password"
                                        id="txt-password-user"
                                        disabled={stateDynamic.loadPassword}
                                        onKeyUp={e => {
                                            e.preventDefault()
                                            if (e.keyCode === 13) {
                                                searchUserPassword({ setStateDynamic, stateDynamic, row, setRow, store, setExecValidation })
                                                return false
                                            }
                                        }}
                                    />
                                    <Button size="sm" className="ml-2 mr-2 align-self-center" onClick={_ => {
                                        searchUserPassword({ setStateDynamic, stateDynamic, row, setRow, store, setExecValidation })
                                    }}>{stateDynamic.loadPassword ? <i className="fa fa-lock"></i> : <i className="fa fa-lock-open"></i>}</Button>
                                </div>
                            </div>


                        </div>

                        {parseInt(stateDynamic.customer?.id_customers) >= 0 ? <>
                            <div className="col-12 mb-2 mt-2">
                                <strong className="text-primary">Información de Cliente</strong>
                            </div>
                            {renderSelect({
                                text: 'Cliente', name: 'id_customers', nameSelect: 'customers',
                                label: ({ item }) => {
                                    return item.id_customers + ' - ' + item.first_name + ' ' + item.last_name
                                },
                                addParams: ({ item }) => {
                                    return {
                                        customer: item
                                    }
                                },
                                options: {
                                    smallColor: "text-dark font-weight-bold",
                                    classNameParent: 'col-md-4 mb-2',
                                    icon: '',
                                    size: '',
                                    onChange: e => {
                                        setStateDynamic({
                                            ...stateDynamic,
                                            customer: e.customer
                                        })
                                        setRow({
                                            ...row,
                                            p_id_customers: e.value
                                        })
                                    },
                                    required: true,
                                    append: [
                                        <Button variant="secondary" onClick={_ => {
                                            document.getElementById('button_show_customers').click()
                                        }}><i className="fa fa-plus"></i></Button>
                                    ],
                                    // disabled: stateDynamic.isReadOnly
                                },
                            })}
                            {renderInput({
                                name: 'phone_customers',
                                text: 'Telefono',
                                options: {
                                    smallColor: "text-dark font-weight-bold",
                                    classNameParent: 'col-md-2 mb-2',
                                    size: '',
                                    disabled: true,
                                    icon: '',
                                    value: stateDynamic.customer.phone
                                }
                            })}
                            {renderInput({
                                name: 'email_customers',
                                text: 'Email',
                                options: {
                                    smallColor: "text-dark font-weight-bold",
                                    classNameParent: 'col-md-4 mb-2',
                                    size: '',
                                    disabled: true,
                                    icon: '',
                                    value: stateDynamic.customer.email
                                }
                            })}
                            <div className="col-12 mb-2">
                                <hr className="mb-2 mt-2" />
                                <strong className="text-primary">Información de la Factura</strong>
                            </div>
                            {renderInput({
                                name: 'condition_pay_document',
                                text: 'Condición de Pago',
                                options: {
                                    smallColor: "text-dark font-weight-bold",
                                    classNameParent: 'col-md-3 mb-2',
                                    size: '',
                                    icon: '',
                                    disabled: (stateDynamic.isReadOnly && !stateDynamic.loadPassword)
                                }
                            })}
                            {renderSelect({
                                text: 'Uso CFDI', name: 'id_cdfi', nameSelect: 'cdfi',
                                label: ({ item }) => {
                                    return item.description_cdfi
                                },
                                options: {
                                    smallColor: "text-dark font-weight-bold",
                                    classNameParent: 'col-md-3 mb-2',
                                    icon: '',
                                    size: '',
                                    required: true,
                                    disabled: (stateDynamic.isReadOnly && !stateDynamic.loadPassword)
                                }
                            })}
                        </> : ''}
                        {renderSelect({
                            text: 'Forma de Pago', name: 'id_format_pay', nameSelect: 'format_pay',
                            label: ({ item }) => {
                                return item.description_format_pay
                            },
                            options: {
                                smallColor: "text-dark font-weight-bold",
                                classNameParent: 'col-md-3 mb-2',
                                icon: '',
                                size: '',
                                required: true,
                                disabled: (stateDynamic.isReadOnly && !stateDynamic.loadPassword),
                                onChange: e => {
                                    setStateDynamic({
                                        ...stateDynamic,
                                        id_format_pay: e.value
                                    })
                                    setRow({
                                        ...row,
                                        p_id_format_pay: e.value
                                    })
                                }
                            },
                        })}
                        {renderSelect({
                            text: 'Método de Pago', name: 'id_method_pay', nameSelect: 'method_pay',
                            label: ({ item }) => {
                                return item.description_method_pay
                            },
                            options: {
                                smallColor: "text-dark font-weight-bold",
                                classNameParent: 'col-md-3 mb-2',
                                icon: '',
                                size: '',
                                required: true,
                                disabled: (stateDynamic.isReadOnly && !stateDynamic.loadPassword)
                            }
                        })}
                        {parseInt(stateDynamic.id_format_pay) > 2 ? <>
                            {renderInput({
                                name: 'bank',
                                text: 'Banco de la Tarjeta',
                                options: {
                                    smallColor: "text-dark font-weight-bold",
                                    classNameParent: 'col-md-2 mb-2',
                                    size: '',
                                    icon: '',
                                    // value: stateDynamic.bank,
                                    // onChange: e => {
                                    //     setStateDynamic({
                                    //         ...stateDynamic,
                                    //         bank: e.value
                                    //     })
                                    // }
                                }
                            })}
                            {renderInput({
                                name: 'card_digits',
                                text: 'Últimos 4 digitos',
                                options: {
                                    smallColor: "text-dark font-weight-bold",
                                    classNameParent: 'col-md-2 mb-2',
                                    size: '',
                                    icon: '',
                                    // value: stateDynamic.card_digits,
                                    // onChange: e => {
                                    //     setStateDynamic({
                                    //         ...stateDynamic,
                                    //         card_digits: e.value
                                    //     })
                                    // }
                                }
                            })}
                        </> : ''}
                        <div className="col-12 mb-2">
                            <hr className="mb-2" />
                            <strong className="text-primary">Detalle Venta</strong>
                        </div>
                        {stateDynamic.isReadOnly ? '' : <div className="col-md-6 mb-3">
                            <Select
                                autoFocus
                                smallColor="text-dark font-weight-bold"
                                classNameParent=""
                                size=""
                                options={state?.product ? state.product.map(p => {//TODO:: OJOTE AQUI
                                    return {
                                        label: `${p.description_product} - ${p.upc_product} - ${p.id_item_global_product} - ${p.key_product}`,
                                        value: p.id_product,
                                        product: p,
                                        isDisabled: isNaN(parseFloat(p.price_product)) || stateDynamic?.products?.some(pp => parseInt(pp.id_product) === parseInt(p.id_product))
                                    }
                                }) : []}

                                onFocus={_ => setExecValidation(false)}
                                onBlur={_ => setExecValidation(true)}
                                value={''}
                                onChange={e => {
                                    addProduct({ setStateDynamic, stateDynamic, product: e.product })
                                }}
                                placeholder="Buscar por Clave, UPC, Item Global, Descripción"
                            />
                        </div>}

                        <div className="table-responsive">
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th style={{ width: 10 }}>Línea</th>
                                        <th style={{ minWidth: 100 }}>Producto</th>
                                        <th style={{ minWidth: 100 }}>Precio</th>
                                        <th style={{ minWidth: 100 }}>Descuento</th>
                                        <th style={{ minWidth: 150, maxWidth: 150, width: 150 }}>Cantidad</th>
                                        <th style={{ minWidth: 100 }}>Importe</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {React.Children.toArray(stateDynamic?.products?.map((c, i) => {
                                        let subtotal = '0.00';
                                        if (!isNaN(parseInt(c.qty_document_det))) {
                                            subtotal = (parseFloat(c.price_document_det) * parseInt(c.qty_document_det)).toFixed(2)
                                        }
                                        subtotal -= calculateDiscount(c)

                                        return <tr>
                                            <td className="text-center">{i + 1}</td>
                                            <td>
                                                <strong>{c.description_product}</strong><br />
                                                <small>Marca: {c.brand_product}</small><br />
                                                <small>Unidad de Medida: {c.description_unit_measure}</small><br />
                                                <small>Clave: {c.key_product}</small>
                                            </td>
                                            <td className="text-center">
                                                <strong className="text-primary">$ {Helpers.number.withCommas({ number: parseFloat(c.price_document_det) })}</strong>
                                            </td>
                                            <td className="text-center">
                                                <strong className="text-primary">{(c.discount_type_document_det == 'PERCENTAGE') ? '%' : '$'} {c.discount_document_det}</strong>
                                                {(stateDynamic.loadPassword && stateDynamic.isReadOnly == false) ?
                                                    <div className="row">
                                                        <Radio
                                                            classNameParent="col-md-4"
                                                            name={"rdb-type-discount-add-no" + i}
                                                            id={"rdb-type-discount-add-no" + i}
                                                            text="No"
                                                            checked={c.discount_type_document_det === 'SIN_DESCUENTO'}
                                                            onChange={e => {
                                                                setStateDynamic({
                                                                    ...stateDynamic,
                                                                    products: stateDynamic.products.map((pp, ii) => {
                                                                        if (i === ii) {
                                                                            pp.discount_type_document_det = 'SIN_DESCUENTO'
                                                                            pp.discount_document_det = 0
                                                                        }

                                                                        return pp
                                                                    })
                                                                })
                                                            }}
                                                        />
                                                        <Radio
                                                            classNameParent="col-md-4"
                                                            name={"rdb-type-discount-add-price" + i}
                                                            id={"rdb-type-discount-add-price" + i}
                                                            text="Precio"
                                                            checked={c.discount_type_document_det === 'PRICE'}
                                                            onChange={e => {
                                                                setStateDynamic({
                                                                    ...stateDynamic,
                                                                    products: stateDynamic.products.map((pp, ii) => {
                                                                        if (i === ii) {
                                                                            pp.discount_type_document_det = 'PRICE'
                                                                        }

                                                                        return pp
                                                                    })
                                                                })
                                                            }}
                                                        />
                                                        <Radio
                                                            classNameParent="col-md-4"
                                                            name={"rdb-type-discount-add-percentage" + i}
                                                            id={"rdb-type-discount-add-percentage" + i}
                                                            text="Porcentaje"
                                                            checked={c.discount_type_document_det === 'PERCENTAGE'}
                                                            onChange={e => {
                                                                setStateDynamic({
                                                                    ...stateDynamic,
                                                                    products: stateDynamic.products.map((pp, ii) => {
                                                                        if (i === ii) {
                                                                            pp.discount_type_document_det = 'PERCENTAGE'
                                                                        }

                                                                        return pp
                                                                    })
                                                                })
                                                            }}
                                                        />
                                                        <Input
                                                            min="1"
                                                            step="any"
                                                            type="number"
                                                            className="text-center"
                                                            classNameParent="col-md-12"
                                                            value={c.discount_document_det}
                                                            onChange={e => {
                                                                if (c.discount_type_document_det == 'PERCENTAGE' && parseFloat(e.currentTarget.value) > 50) {
                                                                    return false;
                                                                }
                                                                setStateDynamic({
                                                                    ...stateDynamic,
                                                                    products: stateDynamic.products.map((pp, ii) => {
                                                                        if (i === ii) {
                                                                            pp.discount_document_det = e.currentTarget.value
                                                                        }

                                                                        return pp
                                                                    })
                                                                })
                                                            }}
                                                        />
                                                    </div>

                                                    : ''}

                                            </td>
                                            <td className="text-center">
                                                {stateDynamic.isReadOnly ? c.qty_document_det : <>
                                                    <Input
                                                        min="1"
                                                        step="1"
                                                        type="number"
                                                        className="text-center"
                                                        value={c.qty_document_det}
                                                        onChange={e => {
                                                            setStateDynamic({
                                                                ...stateDynamic,
                                                                products: stateDynamic.products.map((pp, ii) => {
                                                                    if (i === ii) {
                                                                        pp.qty_document_det = e.currentTarget.value
                                                                    }

                                                                    return pp
                                                                })
                                                            })
                                                        }}
                                                        //max={parseInt(c.current_stock)}
                                                        required
                                                    />
                                                    <div>
                                                        <small>Stock: <strong>{(!isNaN(parseInt(data?.id_document)) ? parseInt(c.qty_document_det) : 0) + parseInt(c.current_stock)}</strong></small>
                                                    </div>
                                                </>}

                                            </td>

                                            <td className="text-center"><strong className="text-primary">$ {Helpers.number.withCommas({ number: subtotal })}</strong></td>

                                            <td className="text-center">
                                                {stateDynamic.isReadOnly ? '' : <Button variant="danger" size="xs" onClick={_ => {
                                                    setStateDynamic({
                                                        ...stateDynamic,
                                                        products: stateDynamic.products.filter((pp, ii) => {
                                                            return i !== ii
                                                        })
                                                    })
                                                }}>
                                                    <i className="fa fa-trash"></i>
                                                </Button>}
                                            </td>
                                        </tr>
                                    }))}
                                </tbody>
                            </table>
                        </div>
                        {renderTextArea({
                            name: 'observation_document',
                            text: 'Observación',
                            options: {
                                smallColor: "text-dark font-weight-bold",
                                classNameParent: 'col-md-6 mb-2',
                                size: '',
                                icon: '',
                                rows: 5,
                                disabled: stateDynamic.isReadOnly,
                                render: r => {
                                    return "Contenido de observación " + r.observation_document + data.reason_disabled;
                                },
                                value: (data.document_enabled) ? '' : `${data.observation_document == null ? '' : data.observation_document} \nCancelado por: ${data.name_disabled == null ? '' : data.name_disabled} \nMotivo: ${data.reason_disabled == null ? '' : data.reason_disabled} \nFecha: ${data.disabled_at == null ? '' : data.disabled_at}`
                            }
                        })}
                        <div className="col-md-6">
                            <div className="table-responsive">
                                <table className="table table-sm">
                                    <tfoot>
                                        <tr>
                                            <th width="10%"></th>
                                            <th width="10%"></th>
                                            <th width="10%"></th>
                                            <th width="30%" className="text-right" style={{ verticalAlign: 'middle' }}>Importe</th>
                                            <td width="20%" className="text-center" width="20%" style={{ fontSize: 20 }}>{Helpers.number.withCommas({ number: parseFloat(subtotales.import).toFixed(2) })}</td>
                                            <th width="10%"></th>
                                            <th width="10%"></th>
                                        </tr>

                                        <tr>
                                            <th width="10%"></th>
                                            <th width="10%"></th>
                                            <th width="10%"></th>
                                            <th width="30%" className="text-right" style={{ verticalAlign: 'middle' }}>Descuento</th>
                                            <td width="20%" className="text-center" width="20%" style={{ fontSize: 20 }}>{Helpers.number.withCommas({ number: parseFloat(subtotales.discountAdd).toFixed(2) })}</td>
                                            <th width="10%"></th>
                                            <th width="10%"></th>
                                        </tr>

                                        <tr>
                                            <th width="10%"></th>
                                            <th width="10%"></th>
                                            <th width="10%"></th>
                                            <th width="30%" className="text-right" style={{ verticalAlign: 'middle' }}>Subtotal</th>
                                            <td width="20%" className="text-center" style={{ fontSize: 20 }}>{Helpers.number.withCommas({ number: parseFloat(subtotales.subtotal).toFixed(2) })}</td>
                                            <th width="10%"></th>
                                            <th width="10%"></th>
                                        </tr>
                                        <tr>
                                            <th width="10%"></th>
                                            <th width="10%"></th>
                                            <th width="10%"></th>
                                            <th width="30%" className="text-right" style={{ verticalAlign: 'middle' }}>IVA (16%)</th>
                                            <td width="20%" className="text-center" style={{ fontSize: 20 }}>{Helpers.number.withCommas({ number: parseFloat(subtotales.iva).toFixed(2) })}</td>
                                            <th width="10%"></th>
                                            <th width="10%"></th>
                                        </tr>
                                        <tr>
                                            <th width="10%"></th>
                                            <th width="10%"></th>
                                            <th width="10%"></th>
                                            <th width="30%" className="text-right" style={{ verticalAlign: 'middle' }}>Total</th>
                                            <th width="20%" className="text-primary" style={{ fontSize: 20 }}>{Helpers.number.withCommas({ number: parseFloat(subtotales.total).toFixed(2) })}</th>
                                            <th width="10%"></th>
                                            <th width="10%"></th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </>
                },
                formCustomOptions: ({ data }) => {
                    return {
                        viewSubmit: true
                    }
                }
            }}
            modalSize="xl"
            removeDescription
            actionsCustom
            actions={({ dataButtons, handleGet, handleShowConfirm, setOptionsConfirm, data, setReload, setLoadSubmitConfirm, setShowConfirm, setModalShowAdd, setModalBodyAdd }) => {
                return <>
                    <Button variant="outline-info" className="mr-2" size="sm" {...dataButtons} onClick={handleGet}>
                        <i className="fa fa-eye"></i>
                    </Button>
                    {parseInt(data.enabled_document) === 1 ? <>
                        <Button variant="dark" size="sm" className="mr-2" onClick={_ => {
                            setModalBodyAdd(<iframe style={{
                                width: '100%', minHeight: '500px'
                            }} src={Helpers.config.apiUrl({ url: '/document/ticket/' + data.id_document })}></iframe>)
                            setModalShowAdd(true)
                        }}>
                            <i className="fa fa-print"></i>
                        </Button>
                        <Button variant="dark" size="sm" className="mr-2" onClick={_ => {
                            setModalBodyAdd(<iframe style={{
                                width: '100%', minHeight: '500px'
                            }} src={Helpers.config.apiUrl({ url: '/document/ticket_gift/' + data.id_document })}></iframe>)
                            setModalShowAdd(true)
                        }}>
                            <i className="fa fa-gift"></i>
                        </Button>
                        <Button variant="outline-danger" size="sm" {...dataButtons} onClick={e => {
                            handleShowConfirm(e)
                            setOptionsConfirm({
                                title: '¿Anular la venta?',
                                subtitle: `#${data.id_document}`,
                                confirmButtonText: '¡Sí, anular!',
                                icon: 'ban',
                                onConfirm: ({ states, setStates }) => {
                                    if (!states.passwordUser) {
                                        Helpers.toast.warning({
                                            message: 'Ingresar contraseña',
                                            toast
                                        })
                                        // txtPasswordBan.focus()
                                        return
                                    }
                                    setLoadSubmitConfirm(true)
                                    Controllers.users.get_user_password({
                                        p_password: states.passwordUser
                                    }).then(res => {
                                        if (isNaN(parseInt(res.data?.id_user))) {
                                            Helpers.toast.warning({
                                                message: 'Contraseña incorrecta',
                                                toast
                                            })
                                            setStates({
                                                passwordUser: '',
                                            })
                                            // txtPasswordBan.focus()
                                            setLoadSubmitConfirm(false)
                                            return
                                        }
                                        Controllers.document.document_change_enabled({
                                            p_id_document: data.id_document,
                                            p_enabled_document: 0,
                                            p_reason_disabled: states.reason,
                                            p_user_disabled: res.data?.id_user,
                                        }).then(_ => {
                                            Helpers.toast.success({
                                                message: 'Anulado correctamente',
                                                toast
                                            })
                                        }).finally(_ => {
                                            setLoadSubmitConfirm(false)
                                            setReload(true)
                                            setShowConfirm(false)
                                        })
                                    })
                                },
                                dataInit: {
                                    passwordUser: '',
                                    reason: ''
                                },
                                append: ({ states, setStates }) => <div className="text-left">
                                    <Input
                                        text="Contraseña"
                                        size=""
                                        type="password"
                                        placeholder="Contraseña"
                                        value={states.passwordUser}
                                        classNameParent="w-100"
                                        onChange={e => {
                                            setStates({
                                                ...states,
                                                passwordUser: e.currentTarget.value
                                            })
                                        }}
                                        id="txtPasswordBan"
                                        autoComplete="new-password"
                                    />
                                    <Input
                                        text="Motivo de Cancelación"
                                        size=""
                                        type="text"
                                        placeholder="¿Por qué se cancelo esta venta?"
                                        value={states.reason}
                                        classNameParent="w-100"
                                        onChange={e => {
                                            setStates({
                                                ...states,
                                                reason: e.currentTarget.value
                                            })
                                        }}
                                        id="cancellation"
                                    />
                                </div>
                            })
                        }}>
                            <i className="fa fa-ban"></i>
                        </Button></> : ''}
                </>
            }}
        />
    </>
}

export default Sale;