import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

const TextArea = ({ defaultValue, value, onChange, classNameParent, className, text, size, icon, append, smallColor, ...props }) => {
  const propInput = useCallback(() => {
    return defaultValue ? { defaultValue: defaultValue ? defaultValue : '' } : { value: value ? value : '' }
  }, [defaultValue, value])

  return (<div className={classNameParent}>
    <small className={smallColor}>{text}</small>
    <div className={'input-group input-group-' + size}>
      {icon ? <div className="input-group-prepend">
        <div className="input-group-text"><i className={icon}></i></div>
      </div> : ''}
      <textarea {...props} {...propInput()} onChange={onChange} className={'form-control ' + className} />
      <div className="input-group-append">
        {React.Children.toArray(append)}
      </div>
    </div>
  </div>)
}
TextArea.defaultProps = {
  size: 'sm',
  icon: '',
  classNameParent: 'col-12',
  required: false,
  append: [],
  className: '',
  onChange: () => {},
  smallColor: 'text-muted'
}
TextArea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg', '']),
  classNameParent: PropTypes.string,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  append: PropTypes.array,
  maxLength: PropTypes.number,
  onChange: PropTypes.func
}
export default TextArea