import React from 'react';
import MaintenanceCustom from '@views/Maintenance/MaintenanceCustom'
import { toast } from 'react-toastify'
import Helpers from '@components/Helpers/Helpers';
import Controllers from '@components/Controllers/Controllers';

const BranchOffice = _ => {
    return <>
        <MaintenanceCustom namePlural="Sucursales" modalSize="lg" nameSingle="sucursal" separator="la" module="branch_office"
            tableFieldsAdd={[
                {
                    name: 'key_branch_office', align: 'center', text: 'Key', type: 'input', options: {
                        maxLength: 10,
                        required: true,
                        classNameParent: 'col-12 mb-2'
                    }
                },
                {
                    name: 'tax_branch_office', align: 'center', text: 'Impuesto', type: 'input', options: {
                        required: true,
                        type: 'number',
                        step: '0.10',
                        min: "0",
                        classNameParent: 'col-md-6 mb-2',
                    }
                },
                {
                    name: 'id_type_money', nameSelect: 'type_money', text: 'Tipo de Moneda', type: 'select',
                    options: {
                        classNameParent: 'col-md-6 mb-2'
                    },
                    hideOnTable: true
                },
                {
                    name: 'shipping_free_branch_office', align: 'center', text: 'Envío gratis desde', type: 'input', options: {
                        required: true,
                        type: 'number',
                        step: '0.10',
                        min: "0",
                        classNameParent: 'col-md-6 mb-2'
                    }
                },
                {
                    name: 'shipping_price_branch_office', align: 'center', text: 'Costo Envío', type: 'input', options: {
                        required: true,
                        type: 'number',
                        step: '0.10',
                        min: "0",
                        classNameParent: 'col-md-6 mb-2'
                    }
                },
            ]}
            optionsView={{
                beforeRenderForm: ({ ready, setState }) => {
                    Promise.all([
                        Controllers.type_money.get_type_money()
                    ]).then(res => {
                        setState({
                            type_money: res[0].data,
                        })
                        ready()
                    })
                },
                dataStart: [
                    {name: 'key_branch_office', value : ''},
                    {name: 'tax_branch_office', value : 0 },
                    {name: 'shipping_free_branch_office', value : 0},
                    {name: 'shipping_price_branch_office', value : 0},
                    {name: 'id_type_money', value: ''},
                ],
                submitCustom: true,
                submitIsCorrect: ({ data }) => {
                    if (!data.p_id_type_money) {
                        Helpers.toast.warning({ message: 'Seleccionar Tipo de Moneda', toast })
                        return false
                    }

                    return data
                },
            }}
        />
    </>
}

export default BranchOffice;