import React from 'react';
import MaintenanceCustom from '@views/Maintenance/MaintenanceCustom'
import { toast } from 'react-toastify'
import Helpers from '@components/Helpers/Helpers';
import Controllers from '@components/Controllers/Controllers';
import { CheckBox } from '@components/Form/Form'
import { SwitchToggle } from '@components/Form/Form'

/**
 * Warehouse
 * @version 0.9.85
 */
const Warehouse = ({ showFirstLevel }) => {
    return <>
        <MaintenanceCustom title={<>
            <i className="fa fa-warehouse text-secondary mr-2"></i> Almacenes
        </>}
            removeEnabled
            removeDescription
            removeId
            namePlural="Almacenes" nameSingle="Almacen" separator="El" module="warehouse"
            tableFieldsAdd={[
                {
                    name: 'id', align: 'center', text: '#ID',
                    hideOnTable: false,
                    hideOnForm: false, options:{
                        disabled:true
                    }
                },
                {
                    name: 'name', align: 'center', text: 'Nombre', type:'input', hideOnForm:false,options: {
                        maxLength: 255,
                        required: true,
                        classNameParent: 'col-12 mb-2',
                        icon: '',
                        size: '',
                        rows: 5
                      }
                },
                {
                    name: 'address', align: 'center', text: 'Dirección', type:'input', hideOnForm:false,hideOnTable:true,options: {
                        maxLength: 255,
                        required: true,
                        classNameParent: 'col-12 mb-2',
                        icon: '',
                        size: '',
                        rows: 5
                      }
                },
                {
                    name: 'email', align: 'center', text: 'Email', type:'input', hideOnForm:false,options: {
                        maxLength: 255,
                        required: true,
                        classNameParent: 'col-12 mb-2',
                        icon: '',
                        size: '',
                        rows: 5
                      }
                },
                {
                    name: 'pos', align: 'center', text: '¿Punto de venta?',
                    render: r => {
                        return Helpers.components.state({
                            state: parseInt(r.pos),
                            textZero: 'Inactivo',
                            textOne: 'Activo'
                        })
                    },
                    hideOnForm: false
                },
                
            ]}
            optionsView={
                {
                    removeId:true,
                    removeEnabled: true,
                    removeDescription:true,
                    dataStart: [
                        { name: 'id', value: 0 },
                        { name: 'name', value: "" },
                        { name: 'address', value: "" },
                        { name: 'email', value: ""},
                        { name: 'pos', value: 0},
                    ],
                    inputDescriptionOptions: {
                        icon: '',
                        size: ''
                    },
                    append: ({ renderInput, renderSwitch, data, stateDynamic, setStateDynamic }) => {
                        return <>
                            {renderSwitch({
                                name: 'pos', options: {
                                    textUnChecked: 'POS Activo',
                                    textCheck: 'POS Inactivo'
                                }
                            })}
                        </>
                    },
                }
            }
        />
    </>
}

Warehouse.defaultProps = {
    showFirstLevel: true
}

export default Warehouse;