import React, { useState } from 'react';
import MaintenanceCustom from '@views/Maintenance/MaintenanceCustom'
import { toast } from 'react-toastify'
import Helpers from '@components/Helpers/Helpers';
import Controllers from '@components/Controllers/Controllers';
import { CheckBox, Select } from '@components/Form/Form'
import { SwitchToggle, Input, Radio, FormCustom } from '@components/Form/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

/**
 * ProductHistoryM
 * @version 0.9.87
 * Historial de movimientos por sucursal
 */
const ProductHistoryM = ({ showFirstLevel }) => {
    const [detailsMass, setDetailsMass] = useState({})
    const [modalShow, setModalShow] = useState(false)
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [formMass, setFormMass] = useState({
        p_file_excel: ''
    })

    const [modalInventoryShow, setModalInventoryShow] = useState(false)
    const [formInventory, setFormInventory] = useState({
        inventory: ''
    })
    const [detailInventory, setDetailInventory] = useState({})
    const [loadInventory, setLoadInventory] = useState(false)


    const [modalMovesShow, setModalMovesShow] = useState(false)
    const [detailMoves, setDetailMoves] = useState({})
    const [loadMoves, setLoadMoves] = useState(false)
    const [formMoves, setFormMoves] = useState({
        inventory: '',
        type: '',
        id_warehouse: ''
    })
    return <>
        <MaintenanceCustom title={<>
            <i className="fa fa-warehouse text-secondary mr-2"></i> Historial de movimientos S
        </>}
            prependCard={({ setReload }) => <>

                {/* Descarga de inventario */}
                <a href={Helpers.config.resourcesUrl({
                    url: '/product_history_m/download_inventory?jwt=' + Helpers.localStorage.getJWT()
                })} download="Descarga de inventario" className="btn btn-sm btn-primary mr-1"><i className="fas fa-cloud-download-alt mr-2"></i> Descargar inventario
                </a>

                {/* <Button variant="success" onClick={_ => {
                    setDetailsMass({})
                    setModalShow(true)
                }} className="mr-3 btn-lg">
                    <i className="fas fa-cloud-upload-alt mr-2"></i> Carga Traspasos
                </Button>

                <Modal show={modalShow} size="lg" onHide={_ => setModalShow(false)} backdrop="static">
                    <Modal.Header>
                        <Modal.Title as="div">Carga masiva de traspasos</Modal.Title>
                        <div className="text-right align-self-center">
                            <svg className="cur-pointer" onClick={_ => setModalShow(false)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                            </svg>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <strong>Descargar Plantilla
                            <a href={Helpers.config.resourcesUrl({
                                url: '/excel/traspasos.xlsx'
                            })} download="Traspasos" className="ml-2">
                                <Button size="xs" variant="success"><i className="fa fa-file-excel"></i></Button>
                            </a>
                        </strong>
                        <FormCustom loadSubmit={loadSubmit} onSubmit={_ => {
                            setDetailsMass({})
                            setLoadSubmit(true)
                            Controllers.product_history.massTransfers(Helpers.formData.generate({
                                data: formMass,
                                file: [
                                    { name: 'p_file_excel', value: formMass.p_file_excel[0] }
                                ]
                            })).then(res => {
                                setDetailsMass(res)
                            }).catch(req => {
                                Helpers.promise.catch({ req, toast })
                            }).finally(_ => {
                                setLoadSubmit(false)
                                setReload(true)
                            })
                        }}>
                            <Input
                                type="file"
                                text="Archivo Excel"
                                classNameParent="col-12 mb-2"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                required
                                invalid="Campo obligatorio"
                                onChange={e => {
                                    setFormMass({
                                        p_file_excel: e.currentTarget.files.length > 0 ? e.currentTarget.files : ''
                                    })
                                }}
                            />
                        </FormCustom>
                        {detailsMass?.message ? <>
                            <div className="d-flex flex-column mt-3">
                                <div><strong>{detailsMass.message}</strong></div>
                                <div className="text-success">Productos registrados: {detailsMass.data.qty_registerd}</div>
                                <div className="text-success">Productos actualizados: {detailsMass.data.qty_updated}</div>
                                {detailsMass?.data?.errors?.length > 0 ? <>
                                    <div className="text-danger font-weight-bold">Productos por corregir:</div>
                                    <div className="d-flex flex-column">
                                        {React.Children.toArray(detailsMass?.data.errors?.map(r => {
                                            return <div className="text-danger">
                                                <strong>Línea #{r.line}</strong> - {r.message}
                                            </div>
                                        }))}
                                    </div>
                                </> : ''}
                            </div>
                        </> : ''}
                    </Modal.Body>
                </Modal> */}

                {/* Modal de carga de inventario */}
                {/* <Button variant="warning" onClick={_ => {
                    setDetailInventory({})
                    setModalInventoryShow(true)
                }} className="mr-3 btn-sm">
                    <i className="fas fa-cloud-upload-alt mr-2"></i> Carga Inventario
                </Button>
                <Modal show={modalInventoryShow} size="lg" onHide={_ => setModalInventoryShow(false)} backdrop="static">
                    <Modal.Header>
                        <Modal.Title as="div">Carga de Productos</Modal.Title>
                        <div className="text-right align-self-center">
                            <svg className="cur-pointer" onClick={_ => setModalInventoryShow(false)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                            </svg>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <strong>Plantilla de inventario
                            <a href={Helpers.config.resourcesUrl({
                                url: '/excel/inventory_mannager.xlsx'
                            })} download="plantilla_carga_inventario_gerentes" className="ml-2">
                                <Button size="xs" variant="success"><i className="fa fa-file-excel"></i></Button>
                            </a>
                        </strong>
                        <FormCustom loadInventory={loadInventory} onSubmit={_ => {
                            setDetailInventory({})
                            setLoadInventory(true)
                            Controllers.product_history_m.updateExcelInventory(Helpers.formData.generate({
                                data: formInventory,
                                file: [
                                    { name: 'inventory', value: formInventory.inventory[0] }
                                ]
                            })).then(res => {
                                setDetailInventory(res)
                            }).catch(req => {
                                Helpers.promise.catch({ req, toast })
                            }).finally(_ => {
                                setLoadInventory(false)
                                setReload(true)
                            })
                        }}>
                            <Input
                                type="file"
                                text="Archivo Excel"
                                classNameParent="col-12 mb-2"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                required
                                invalid="Campo obligatorio"
                                onChange={e => {
                                    setFormInventory({
                                        inventory: e.currentTarget.files.length > 0 ? e.currentTarget.files : ''
                                    })
                                }}
                            />
                        </FormCustom>
                        {detailInventory?.message ? <>
                            <div className="d-flex flex-column mt-3">
                                <div><strong>{detailInventory.message}</strong></div>
                                <div className="text-success">Productos actualizados: {detailInventory.data.qty_updated}</div>
                                {detailInventory?.data?.errors?.length > 0 ? <>
                                    <div className="text-danger font-weight-bold">Productos por corregir:</div>
                                    <div className="d-flex flex-column">
                                        {React.Children.toArray(detailInventory?.data.errors?.map(r => {
                                            return <div className="text-danger">
                                                <strong>{r}</strong>
                                            </div>
                                        }))}
                                    </div>
                                </> : ''}
                            </div>
                        </> : ''}
                    </Modal.Body>
                </Modal> */}

                {/* Modal de carga de inventario */}
                {/* <Button variant="info" onClick={_ => {
                    setDetailMoves({})
                    setModalMovesShow(true)
                }} className="mr-3 btn-sm">
                    <i className="fas fa-cloud-upload-alt mr-2"></i> Carga Entradas/Salidas
                </Button>
                <Modal show={modalMovesShow} size="lg" onHide={_ => setModalMovesShow(false)} backdrop="static">
                    <Modal.Header>
                        <Modal.Title as="div">Carga de Entradas/Salidas</Modal.Title>
                        <div className="text-right align-self-center">
                            <svg className="cur-pointer" onClick={_ => setModalMovesShow(false)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                            </svg>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <strong>Plantilla de entradas o salidas
                            <a href={Helpers.config.resourcesUrl({
                                url: '/excel/moves.xlsx'
                            })} download="movimientos_al_almacen" className="ml-2">
                                <Button size="xs" variant="success"><i className="fa fa-file-excel"></i></Button>
                            </a>
                        </strong>
                        <FormCustom loadMoves={loadMoves} onSubmit={_ => {
                            setModalMovesShow({})
                            setLoadMoves(true)
                            Controllers.product_history_m.moves(Helpers.formData.generate({
                                data: formMoves,
                                file: [
                                    { name: 'inventory', value: eval(formMoves.inventory) ? formMoves.inventory[0] : '' }
                                ]
                            })).then(res => {
                                setDetailMoves(res)
                            }).catch(req => {
                                Helpers.promise.catch({ req, toast })
                            }).finally(_ => {
                                setLoadMoves(false)
                                setReload(true)
                            })
                        }}>
                            <Select
                                text="Tipo de movimiento"
                                classNameParent="col-12 mb-2"
                                required
                                invalid="Campo obligatorio"
                                options={[
                                    { value: '', label: 'Seleccionar' },
                                    { value: 'entry', label: 'Entradas' },
                                    { value: 'exit', label: 'Salidas' },
                                ]}
                                onChange={e => {
                                    formMoves.type = e.value;
                                    // setFormMoves({
                                    //     type: e.value
                                    // })
                                }}
                            />
                            <Input
                                type="file"
                                text="Archivo Excel"
                                classNameParent="col-12 mb-2"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                required
                                invalid="Campo obligatorio"
                                onChange={e => {
                                    formMoves.inventory = e.currentTarget.files.length > 0 ? e.currentTarget.files : '';
                                    // setFormMoves({
                                    //     inventory: e.currentTarget.files.length > 0 ? e.currentTarget.files : ''
                                    // })
                                }}
                            />
                        </FormCustom>
                        {detailMoves?.message ? <>
                            <div className="d-flex flex-column mt-3">
                                <div><strong>{detailMoves.message}</strong></div>
                                <div className="text-success">Productos actualizados: {detailMoves.data.qty_updated}</div>
                                {detailMoves?.data?.errors?.length > 0 ? <>
                                    <div className="text-danger font-weight-bold">Productos por corregir:</div>
                                    <div className="d-flex flex-column">
                                        {React.Children.toArray(detailMoves?.data.errors?.map(r => {
                                            return <div className="text-danger">
                                                <strong>{r}</strong>
                                            </div>
                                        }))}
                                    </div>
                                </> : ''}
                            </div>
                        </> : ''}
                    </Modal.Body>
                </Modal> */}
            </>}
            removeId
            removeEnabled
            removeDescription
            actionsCustom
            actions={({ updateButton, deleteButton, data }) => {
                return ''
            }}
            namePlural="Movimientos" nameSingle="Movimiento" separator="El" module="product_history_m"
            tableFieldsAdd={[
                {
                    name: 'id_product', nameSelect: 'product', text: 'Producto', type: 'select',
                    hideOnTable: true,
                    hideOnForm: true
                },
                {
                    name: 'type', nameSelect: 'type', text: 'Tipo de movimiento', type: 'select',
                    hideOnForm: true,
                    options: {
                        classNameParent: 'col-12 mb-2',
                        size: '',
                        icon: '',
                        smallColor: 'font-weight-bold text-dark',
                        required: true
                    },
                    hideOnTable: true
                },
                {
                    name: 'id', align: 'center', text: '#ID',
                    hideOnForm: true
                },
                {
                    name: 'id_item_global_product', align: 'center', text: 'Item Global', type: "input",
                    hideOnForm: true,
                    options: {
                        maxLength: 100,
                        required: true,
                        classNameParent: 'col-md-12 mb-2',
                        icon: '',
                        size: '',
                        smallColor: 'font-weight-bold text-dark'
                    },
                },
                {
                    name: 'key_product', align: 'center', text: 'SKU',
                    hideOnForm: true
                },
                {
                    name: 'warehouse', align: 'center', text: 'Almacen',
                    hideOnForm: true
                },
                {
                    name: 'document', align: 'center', text: 'Documento',
                    hideOnForm: true,
                    type: 'input',
                    options: {
                        maxLength: 100,
                        required: false,
                        classNameParent: 'col-md-12 mb-2',
                        icon: '',
                        size: '',
                        smallColor: 'font-weight-bold text-dark'
                    }
                },
                {
                    name: 'amount', align: 'center', text: 'Cantidad',
                    hideOnForm: true,
                    type: 'input',
                    options: {
                        maxLength: 100,
                        required: true,
                        classNameParent: 'col-md-12 mb-2',
                        icon: '',
                        size: '',
                        smallColor: 'font-weight-bold text-dark'
                    }
                },
                {
                    name: 'description', align: 'center', text: 'Descripción',
                    hideOnForm: true,
                    type: 'input',
                    options: {
                        maxLength: 100,
                        required: true,
                        classNameParent: 'col-md-12 mb-2',
                        icon: '',
                        size: '',
                        smallColor: 'font-weight-bold text-dark'
                    }
                },
                {
                    name: 'created_at', align: 'center', text: 'Creado',
                    hideOnForm: true, render: r => {
                        const fecha = new Date(r.created_at)
                        let año = fecha.getFullYear();
                        let mes = ('0' + (fecha.getMonth() + 1)).slice(-2); // Agregar 1 al mes ya que en JavaScript los meses van de 0 a 11
                        let dia = ('0' + fecha.getDate()).slice(-2);
                        // Obtener los componentes de la hora
                        let horas = ('0' + fecha.getHours()).slice(-2);
                        let minutos = ('0' + fecha.getMinutes()).slice(-2);
                        // Formatear la fecha y la hora
                        let fechaFormateada = año + '-' + mes + '-' + dia + ' ' + horas + ':' + minutos;

                        return fechaFormateada
                    }
                },
                {
                    name: 'id_warehouse', nameSelect: 'warehouse', text: 'Almacen', type: 'select',
                    options: {
                        classNameParent: 'col-12 mb-2',
                        size: '',
                        icon: '',
                        smallColor: 'font-weight-bold text-dark',
                        required: true
                    },
                    hideOnTable: true,
                    hideOnForm: true
                },
            ]}
            optionsView={
                {
                    removeEnabled: true,
                    removeDescription: true,
                    beforeRenderForm: ({ ready, data, setState, setStateDynamic }) => {

                        let promises = [
                            Controllers.warehouse.get_warehouse(),
                            Controllers.product.get_product(),
                        ]

                        Promise.all(promises).then(res => {
                            setState({
                                warehouse: res[0].data,
                                product: res[1].data,
                                type: [
                                    { id_type: 'entry', description_type: 'Entrada' },
                                    { id_type: 'exit', description_type: 'Salida' },
                                ]
                            })
                            setStateDynamic({
                                warehouse: !isNaN(data.id) ? res[0].data : [],
                                product: res[1].data,
                                type: [
                                    { id_type: 'entry', description_type: 'Entrada' },
                                    { id_type: 'exit', description_type: 'Salida' },
                                ]
                            })
                            ready()
                        })
                    },
                }

            }
        />
    </>
}

ProductHistoryM.defaultProps = {
    showFirstLevel: true
}

export default ProductHistoryM;