import React, { useMemo, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

/**
 * SweetAlert
 * @version 0.8.0
 * @param {*} param0 
 * @returns 
 * @see Es una cochinada esta clase, hay que abandonarla
 */
const SweetAlert = ({ show, onHide, title, subtitle, icon, confirmButtonText, cancelButtonText, showCancelButton, onConfirm, onCancel, loadSubmit, append, dataInit }) => {
    const [states, setStates] = useState(dataInit)

    const showIcon = useMemo(() => {
        let iconClass = ''
        if (icon === 'delete') {
            iconClass = 'fa fa-trash text-danger'
        } else if (icon === 'ban') {
            iconClass = 'fa fa-ban text-danger'
        } else if (icon === 'shopping') {
            iconClass = 'fa fa-shopping-cart text-success'
        }
        return iconClass === '' ? '' : <i className={iconClass + ' fa-5x mt-4 mb-4'}></i>
    }, [icon])

    const renderButtons = useMemo(() => {
        let buttons = ''

        if (icon === 'delete' || icon === 'ban') {
            buttons = <>
                <Button disabled={loadSubmit} variant={`danger ${showCancelButton ? ' mr-2' : ''}`} onClick={_ => {
                    onConfirm({ states, setStates })
                }}>
                    {loadSubmit ? <i className="fa fa-circle-notch fa-spin"></i> : confirmButtonText}
                </Button>
                {showCancelButton ? <Button variant="outline-dark" onClick={onCancel}>{cancelButtonText}</Button> : ''}
            </>
        } else if (icon === 'shopping') {
            buttons = <>
                {showCancelButton ? <Button variant="outline-dark mr-2" onClick={onCancel}>{cancelButtonText}</Button> : ''}
                <Button disabled={loadSubmit} variant={`success ${showCancelButton ? ' mr-2' : ''}`} onClick={_ => {
                    onConfirm({ states, setStates })
                }}>
                    {loadSubmit ? <i className="fa fa-circle-notch fa-spin"></i> : confirmButtonText}
                </Button>
            </>
        }

        return buttons
    }, [icon, loadSubmit, showCancelButton, onConfirm, confirmButtonText, states ])

    return <Modal show={show} onHide={onHide} backdrop="static" centered>
        <Modal.Body className="text-center">
            {showIcon}

            <h3><strong>{title}</strong></h3>
            <p>{subtitle}</p>
            {append({
                states, setStates
            })}
            <div className="mt-4">
                {renderButtons}
            </div>
        </Modal.Body>
    </Modal>
}

SweetAlert.defaultProps = {
    title: '',
    subtitle: '',
    icon: '',
    show: false,
    onHide: () => {},
    onConfirm: () => {},
    confirmButtonText: '',
    onCancel: () => {},
    cancelButtonText: '',
    showCancelButton: true,
    loadSubmit: false,
    append: _ => {},
    dataInit: {}
}

export default SweetAlert;