import React, { useState } from 'react';
import MaintenanceCustom from '@views/Maintenance/MaintenanceCustom'
import { toast } from 'react-toastify'
import Helpers from '@components/Helpers/Helpers';
import Controllers from '@components/Controllers/Controllers';
import Button from 'react-bootstrap/esm/Button';

/**
 * Worker
 * @version 0.9.93
 */
const Worker = _ => {

    const [worker, setWorker] = useState({})

    return <>
        <MaintenanceCustom title={<>
            <i className="fa fa-list text-secondary mr-2"></i>Listado de Usuarios
        </>}
            namePlural="Usuarios" nameSingle="Usuario" separator="el" module="worker"
            removeDescription
            removeEnabled
            tableFieldsAdd={[
                {
                    name: 'name_worker', align: 'center', text: 'Nombres', type: 'input', options: {
                        maxLength: 100,
                        required: true,
                        classNameParent: 'col-md-6 mb-2',
                        icon: '',
                        size: '',
                        smallColor: 'font-weight-bold text-dark'
                    }
                },
                {
                    name: 'last_name_worker', align: 'center', text: 'Apellidos', type: 'input', options: {
                        maxLength: 100,
                        required: true,
                        classNameParent: 'col-md-6 mb-2',
                        icon: '',
                        size: '',
                        smallColor: 'font-weight-bold text-dark'
                    }
                },
                {
                    name: 'email_worker', align: 'center', text: 'Email', type: 'input', options: {
                        maxLength: 255,
                        required: true,
                        classNameParent: 'col-md-6 mb-2',
                        icon: '',
                        size: '',
                        smallColor: 'font-weight-bold text-dark',
                        type: 'email'
                    }
                },
                {
                    name: 'phone_worker', align: 'center', text: 'Telefono', type: 'input', options: {
                        maxLength: 30,
                        required: true,
                        classNameParent: 'col-md-6 mb-2',
                        icon: '',
                        size: '',
                        smallColor: 'font-weight-bold text-dark'
                    }
                },
                {
                    name: 'id_profile', nameSelect: 'profile', text: 'Rol', type: 'select',
                    options: {
                        classNameParent: 'col-12 mb-2',
                        size: '',
                        icon: '',
                        smallColor: 'font-weight-bold text-dark',
                        required: true
                    },
                    hideOnTable: true
                },
                {
                    name: 'id_warehouse', nameSelect: 'warehouse', text: 'Almacen predeterminado', type: 'select',
                    options: {
                        classNameParent: 'col-12 mb-2',
                        size: '',
                        icon: '',
                        smallColor: 'font-weight-bold text-dark',
                        required: true
                    },
                    hideOnTable: true
                },
                {
                    name: 'photo_worker', text: 'Foto', type: 'file', options: {
                        text: 'Foto',
                        classNameParent: 'col-md-12 mb-2',
                        icon: '',
                        size: '',
                        accept: 'image/*',
                        smallColor: 'font-weight-bold text-dark',
                        required: false
                    },
                    render: r => {
                        return <div className="d-flex">
                            {r.photo_worker ? <img src={Helpers.config.resourcesUrl({ url: r.photo_worker }) + '?' + Helpers.date.get({ format: '%Y%m%d%H%i%s' })} className="img-fluid mr-2" style={{ maxWidth: 50, maxHeight: 50 }} alt={r.description_worker} /> : ''}
                        </div>
                    },
                    onChange: ({ e, setStateDynamic, stateDynamic }) => {
                        if (e.currentTarget.files.length === 0) {
                            setStateDynamic({
                                ...stateDynamic,
                                photo_worker: ''
                            })
                        } else {
                            let files = e.currentTarget.files
                            let selectedFile = files[0]
                            let reader = new FileReader()
                            reader.onload = event => {
                                setStateDynamic({
                                    ...stateDynamic,
                                    photo_worker_render: event.target.result,
                                    photo_worker: files
                                })
                            }
                            reader.readAsDataURL(selectedFile)
                        }
                    },
                    renderAfter: ({ stateDynamic, row }) => {
                        return <div className="col-12 mb-2">
                            {row.p_photo_worker && !stateDynamic.photo_worker_render ? <img src={Helpers.config.resourcesUrl({ url: row.p_photo_worker })} className="img-fluid" style={{ maxWidth: 200 }} alt="Imagen" /> : (stateDynamic.photo_worker_render ? <img src={stateDynamic.photo_worker_render} className="img-fluid" style={{ maxWidth: 200 }} alt="Imagen" /> : '')}
                        </div>
                    }
                },
                {
                    name: 'description_profile', align: 'center', text: 'Role',
                    hideOnForm: true
                },
                {
                    name: 'description_warehouse', align: 'center', text: 'Almacen',
                    hideOnForm: true
                },
                {
                    name: 'username_user', align: 'center', text: 'Usuario',
                    hideOnForm: true
                },
                {
                    name: 'exec_user', align: 'center', text: '¿Contraseña Master?',
                    render: r => {
                        return Helpers.components.state({
                            state: parseInt(r.exec_user),
                            textZero: 'NO',
                            textOne: 'SI'
                        })
                    },
                    hideOnForm: true
                },
            ]}
            optionsView={{
                removeDescription: true,
                removeEnabled: true,
                dataStart: [
                    { name: 'name_worker', value: '' },
                    { name: 'last_name_worker', value: '' },
                    { name: 'email_worker', value: '' },
                    { name: 'phone_worker', value: '' },
                    { name: 'id_profile', value: '' },
                    { name: 'id_warehouse', value: '' },
                    { name: 'enabled_worker', value: 0 },
                    { name: 'exec_user', value: 0 },
                    { name: 'username_user', value: '' },
                    { name: 'password_user', value: '' },
                    { name: 'photo_worker', value: '' },
                ],
                beforeRenderForm: ({ setState, setStateDynamic, stateDynamic, ready }) => {

                    let promises = [
                        Controllers.profile.get_profile(),
                        Controllers.warehouse.get_warehouse(),
                        Controllers.worker.current(),
                    ]

                    Promise.all(promises).then(res => {
                        setState({
                            profile: res[0].data,
                            warehouse: res[1].data,
                        })
                        setWorker(res[2].data);
                        setStateDynamic({
                            ...stateDynamic,
                            viewPassword: false
                        })
                        ready()
                    })
                },
                submitCustom: true,
                submitIsCorrect: ({ data, stateDynamic }) => {

                    if (!data?.p_id_profile) {
                        Helpers.toast.warning({
                            message: 'Seleccionar Perfil',
                            toast
                        })
                        return false
                    }

                    if (!data?.p_id_warehouse) {
                        Helpers.toast.warning({
                            message: 'Seleccionar Almacen',
                            toast
                        })
                        return false
                    }

                    return Helpers.formData.generate({
                        data,
                        file: [
                            { name: 'p_photo_worker', value: stateDynamic?.photo_worker?.length > 0 ? stateDynamic.photo_worker[0] : data.p_photo_worker }
                        ]
                    })
                },
                append: ({ renderInput, renderSwitch, data, stateDynamic, setStateDynamic }) => {
                    return <>
                        <div className="col-12">
                            <strong className="text-primary">Acceso al Sistema</strong>
                        </div>
                        {renderInput({
                            text: 'Usuario', name: 'username_user', options: {
                                icon: '',
                                size: '',
                                required: true,
                                disabled: !isNaN(parseInt(data.id_user))
                            }
                        })}
                        {renderInput({
                            text: 'Contraseña', name: 'password_user', options: {
                                icon: '',
                                size: '',
                                required: true,
                                type: 'password',
                                disabled: (worker.id_user === data.id_user) ? false : true
                                // append: [
                                //     <Button variant={stateDynamic.viewPassword ? 'dark' : 'primary'} onClick={_ => {
                                //         setStateDynamic({
                                //             ...stateDynamic,
                                //             viewPassword: !stateDynamic.viewPassword
                                //         })
                                //     }}>
                                //         {stateDynamic.viewPassword ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>}
                                //     </Button>
                                // ]
                            }
                        })}
                        {/* {
                        renderSwitch({
                            name: 'exec_user', options: {
                                textUnChecked: 'Activar Contraseña Master',
                                textCheck: 'Desactivar Contraseña Master'
                            }
                        })
                        } */}
                    </>
                }
            }}
            actionsCustom
            actions={({ updateButton, deleteButton, data }) => {
                if (parseInt(data.id_worker) === 1) {
                    return updateButton
                }
                return <>
                    {updateButton}
                </>
            }}
        />
    </>
}

export default Worker;