import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactTableBootstrap from 'react-table-bootstrap';
import Controllers from '@components/Controllers/Controllers';
import MaintenanceForm from '@components/Maintenance/MaintenanceForm'
import { toast } from 'react-toastify';
import Helpers from '@components/Helpers/Helpers';
import SweetAlert from '@components/SweetAlert/SweetAlert'

const Maintenance = ({ nameSingle, namePlural, separator, module, tableFieldsAdd, optionsView, modalSize, showCard, getParams, removeEnabled, removeDescription, removeId, showCardHeader, buttonNewCircle, title, btnNewColor, beforeCard, modalHeader, modalTitleCustom, modalTitleRender, onlyButton, btnNewOptions, afterInsertUpdate, actions, actionsCustom, optionsEnabled }) => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [dataUpdated, setDataUpdated] = useState({})
    const [moduleCamelCase, setModuleCamelCase] = useState('')

    const [showConfirm, setShowConfirm] = useState(false)
    const [loadSubmitConfirm, setLoadSubmitConfirm] = useState(false)
    const [dataConfirm, setDataDeleted] = useState({})
    const [optionsConfirm, setOptionsConfirm] = useState({})

    /* Modal Title Custom */
    const [modalShowAddd, setModalShowAdd] = useState(false)
    const [modalSizeAdd, setModalSizeAdd] = useState('')
    const [modalHeaderAdd, setModalHeaderAdd] = useState('')
    const [modalBodyAdd, setModalBodyAdd] = useState('')
    const [modalTitleAdd, setModalTitleAdd] = useState('')    

    useEffect(_ => {
        let modCamel = ''
        let explode = module.split('_')
        explode.forEach(ex => {
            modCamel += (ex.charAt(0).toUpperCase() + ex.substr(1, ex.length - 1))
        })
        setModuleCamelCase(modCamel)
    }, [module])

    useEffect(() => {
        setReload(true)
    }, [module])

    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            Controllers[module]['get_' + module](getParams).then(res => {
                setRows(res.data)
            }).catch(req => Helpers.promise.catch({ req, toast }))
                .finally(() => {
                    setIsProccesing(false)
                    setReload(false)
                })
        }
    }, [reload, module])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers[module][module + '_insert_update'](data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setModalShow(false)
                setDataUpdated({})
                afterInsertUpdate({ res, setModalBodyAdd, setModalShowAdd })
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setLoadSubmit(false)
                setReload(true)
            })
    }

    const handleGet = e => {
        let $this = e.currentTarget

        if (!$this.dataset['id' + moduleCamelCase]) {
            setModalTitle(`Registrar ${nameSingle}`)
            setDataUpdated({})
            setModalShow(true);
            return
        }

        let buttonHTML = $this.innerHTML;
        $this.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>'
        let id = $this.dataset['id' + moduleCamelCase]

        let d = {}
        d['p_id_' + module] = id

        Controllers[module]['get_' + module + '_id'](d).then(res => {
            setModalTitle(`Modificar ${nameSingle} #${id}`)
            setDataUpdated({ data: res.data })
            setModalShow(true);
        }).catch(req => {
            Helpers.promise.catch({ req, toast })
            setReload(true)
        })
            .finally(() => $this.innerHTML = buttonHTML)
    }

    const handleConfirm = () => {
        setLoadSubmitConfirm(true)

        Controllers[module][module + '_delete'](dataConfirm).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setShowConfirm(false)
                setDataDeleted({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setReload(true)
                setLoadSubmitConfirm(false)
            })
    }

    const handleHideModalForm = () => {
        setModalShow(false)
        setLoadSubmit(false)
    }

    const handleShowConfirm = e => {
        let d = {}
        d['p_id_' + module] = e.currentTarget.dataset['id' + moduleCamelCase]
        d['p_name_' + module] = e.currentTarget.dataset['name' + moduleCamelCase]
        setDataDeleted(d)
        setShowConfirm(true)
    }

    const handleHideModalConfirm = () => setShowConfirm(false)

    const dtRows = useMemo(() => {
        let rowsDefault = [

        ]
        if (!removeId) {
            rowsDefault.push({ name: 'id_' + module, text: 'ID', align: 'center' })
        }
        if (!removeDescription) {
            rowsDefault.push({ name: 'description_' + module, text: 'Descripción' })
        }

        rowsDefault = [
            ...rowsDefault,
            ...tableFieldsAdd.filter(t => t.hideOnTable !== true),
        ]

        if (!removeEnabled) {
            rowsDefault.push({
                name: 'enabled_' + module, text: 'Estado', align: 'center', render: r => Helpers.components.state({
                    state: parseInt(r['enabled_' + module])
                }), ...optionsEnabled
            })
        }

        return <ReactTableBootstrap
            head={[
                [
                    ...rowsDefault,
                    {
                        name: 'actions', style: { minWidth: 120 }, text: <i className="fa fa-cog"></i>, align: 'center', render: r => {
                            let dataButtons = {}
                            dataButtons['data-id-' + (module.replace('_', '-'))] = r['id_' + module]
                            dataButtons['data-name-' + module.replace('_', '-')] = r['description_' + module]

                            let deleteButton = <Button variant="outline-danger" size="sm" {...dataButtons} onClick={handleShowConfirm}>
                                <i className="fa fa-trash"></i>
                            </Button>
                            let updateButton = <Button variant="outline-info" className="mr-2" size="sm" {...dataButtons} onClick={handleGet}>
                                <i className="fa fa-edit"></i>
                            </Button>
                            return <div>
                                {actionsCustom ? actions({
                                    data: r,
                                    updateButton,
                                    deleteButton,
                                    handleGet,
                                    handleShowConfirm,
                                    dataButtons,
                                    setOptionsConfirm,
                                    setReload,
                                    setLoadSubmitConfirm,
                                    setShowConfirm,
                                    modalShowAddd,
                                    setModalShowAdd,
                                    modalSizeAdd,
                                    setModalSizeAdd,
                                    modalHeaderAdd,
                                    setModalHeaderAdd,
                                    modalBodyAdd,
                                    setModalBodyAdd,
                                    modalTitleAdd,
                                    setModalTitleAdd,
                                }) : <>
                                    {updateButton}
                                    {deleteButton}
                                </>}
                            </div>
                        }
                    },
                ],
            ]}
            rows={rows}
            isProcessing={isProcessing}
        />
    }, [rows, isProcessing, removeEnabled, removeDescription])

    return <>
        {onlyButton ? <>
            {buttonNewCircle ? <Button variant={btnNewColor} onClick={handleGet} {...btnNewOptions}>
                <i className="fa fa-plus"></i>
            </Button> : <Button size="sm" variant={btnNewColor} onClick={handleGet} {...btnNewOptions}>
                <i className="fa fa-plus"></i>
            </Button>}
        </>
            : <>
                {beforeCard({ handleGet, btnNewColor, buttonNewCircle, title, nameSingle, setReload })}
                {showCard ? <Card>
                    {showCardHeader ? <Card.Header className="justify-content-between">
                        <span>Listado De {namePlural}</span>
                        {buttonNewCircle ? <Button variant={btnNewColor} onClick={handleGet} {...btnNewOptions}>
                            <i className="fa fa-plus"></i>
                        </Button> : <Button size="sm" variant={btnNewColor} onClick={handleGet} {...btnNewOptions}>
                            <i className="fa fa-plus mr-1"></i> Registrar {nameSingle}
                        </Button>}
                    </Card.Header> : ''}
                    <Card.Body>
                        {dtRows}
                    </Card.Body>
                </Card> : <>
                    <div className="d-flex justify-content-between pb-2">
                        <strong>Listado De {namePlural}</strong>
                        {buttonNewCircle ? <Button variant="primary" onClick={handleGet}>
                            <i className="fa fa-plus"></i>
                        </Button> : <Button size="sm" variant="primary" onClick={handleGet}>
                            <i className="fa fa-plus mr-1"></i> Registrar {nameSingle}
                        </Button>}
                    </div>
                    <div>
                        {dtRows}
                    </div>
                </>}
            </>}
        <Modal show={modalShow} size={modalSize} onHide={handleHideModalForm} backdrop="static">
            <Modal.Header className={modalHeader}>
                {modalTitleCustom ? modalTitleRender({
                    title: <Modal.Title as="div">{modalTitle}</Modal.Title>,
                    closeModal: _ => setModalShow(false)
                }) : <>
                    <Modal.Title as="div">{modalTitle}</Modal.Title>
                    <div className="text-right align-self-center">
                        <svg className="cur-pointer" onClick={_ => setModalShow(false)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                        </svg>
                    </div>
                </>}
            </Modal.Header>
            <Modal.Body>
                <MaintenanceForm openForm onSubmit={handleInsertUpdate} tableFieldsAdd={tableFieldsAdd} loadSubmit={loadSubmit} {...dataUpdated} module={module} {...optionsView} />
            </Modal.Body>
        </Modal>
        <SweetAlert
            icon="delete"
            show={showConfirm}
            showCancelButton
            title={<>¿Desea eliminar {separator} {nameSingle}?</>}
            subtitle={<>#{dataConfirm['p_id_' + module]} - {dataConfirm['p_name_' + module]}</>}
            confirmButtonText="¡Sí, eliminar!"
            cancelButtonText="¡No!"
            loadSubmit={loadSubmitConfirm}
            onCancel={handleHideModalConfirm}
            onConfirm={handleConfirm}
            {...optionsConfirm}
        />
        <Modal show={modalShowAddd} size={modalSizeAdd} onHide={_ => setModalShowAdd(false)} backdrop="static">
            <Modal.Header className={modalHeaderAdd}>
                {modalTitleCustom ? modalTitleRender({
                    title: <Modal.Title as="div">{modalTitleAdd}</Modal.Title>,
                    closeModal: _ => setModalShowAdd(false)
                }) : <>
                    <Modal.Title as="div">{modalTitle}</Modal.Title>
                    <div className="text-right align-self-center">
                        <svg className="cur-pointer" onClick={_ => setModalShowAdd(false)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                        </svg>
                    </div>
                </>}
            </Modal.Header>
            <Modal.Body>
               {modalBodyAdd}
            </Modal.Body>
        </Modal>
    </>
}

Maintenance.defaultProps = {
    tableFieldsAdd: [],
    optionsView: {},
    modalSize: '',
    showCard: true,
    getParams: {},
    removeEnabled: false,
    removeDescription: false,
    removeId: false,
    showCardHeader: true,
    buttonNewCircle: false,
    title: '',
    btnNewColor: 'primary',
    beforeCard: _ => { },
    modalHeader: '',
    modalTitleCustom: false,
    modalTitleRender: _ => { },
    onlyButton: false,
    btnNewOptions: {},
    afterInsertUpdate: _ => { },
    actions: _ => { },
    actionsCustom: false,
    optionsEnabled: {}
}

export default Maintenance