import React from 'react';
import MaintenanceCustom from '@views/Maintenance/MaintenanceCustom'
import { toast } from 'react-toastify'
import Helpers from '@components/Helpers/Helpers';
import Controllers from '@components/Controllers/Controllers';

/**
 * Customers
 * @version 0.9.82
 */
const Customers = ({ onlyButton, btnNewOptions, afterInsertUpdate }) => {
    return <>
        <MaintenanceCustom
            btnNewOptions={btnNewOptions}
            onlyButton={onlyButton}
            afterInsertUpdate={afterInsertUpdate}
            title={<>
                <i className="fa fa-list text-secondary mr-2"></i>Listado de Clientes
            </>} namePlural="Clientes" nameSingle="Cliente" separator="el" module="customers"
            optionsView={{
                removeDescription: true,
                dataStart: [
                    { name: 'first_name', value: '' },
                    { name: 'last_name', value: '' },
                    { name: 'phone', value: '' },
                    { name: 'email', value: '' },
                    { name: 'tax_document', value: '' },
                    { name: 'note', value: '' },
                    { name: 'enabled', value: '' },
                ],
                // beforeRenderForm: ({ ready, setState }) => {
                //     Promise.all([
                //         Controllers.type_customers.get_type_customers(),
                //         Controllers.country.get_country(),
                //     ]).then(res => {
                //         setState({
                //             type_customers: res[0].data,
                //             country: res[1].data,
                //         })
                //         ready()
                //     })
                // },
                removeEnabled: true,
                submitCustom: true,
                submitIsCorrect: ({ data, stateDynamic }) => {
                    return Helpers.formData.generate({
                        data,
                        file: [
                            { name: 'p_tax_document', value: stateDynamic?.tax_document?.length > 0 ? stateDynamic.tax_document[0] : data.p_tax_document }
                        ]
                    });
                    // return data
                },
                // prepend: ({ renderInput, row, setRow }) => {
                //     return <>
                //         {renderInput({
                //             text: 'Nombres',
                //             name: 'first_name',
                //             options: {
                //                 maxLength: 255,
                //                 required: row.p_company_name_customers === '' && row.p_full_name_customers === '',
                //                 // classNameParent: 'col-md-6 mb-2',
                //                 smallColor: 'text-dark font-weight-bold',
                //                 icon: '',
                //                 size: '',
                //                 onChange: e => {
                //                     setRow({
                //                         ...row,
                //                         p_full_name_customers: e.currentTarget.value,
                //                         p_company_name_customers: ''
                //                     })
                //                 }
                //             }
                //         })}
                //         {renderInput({
                //             text: 'Apellidos',
                //             name: 'last_name',
                //             options: {
                //                 maxLength: 255,
                //                 required: row.p_company_name_customers === '' && row.p_full_name_customers === '',
                //                 // classNameParent: 'col-md-6 mb-2',
                //                 smallColor: 'text-dark font-weight-bold',
                //                 icon: '',
                //                 size: '',
                //                 onChange: e => {
                //                     setRow({
                //                         ...row,
                //                         p_company_name_customers: e.currentTarget.value,
                //                         p_full_name_customers: ''
                //                     })
                //                 }
                //             },
                //         })}
                //     </>
                // }
            }}
            // modalSize="lg"
            removeDescription
            removeEnabled
            tableFieldsAdd={[
                {
                    name: 'first_name', align: 'center', text: 'Nombres', type: 'input', options: {
                        maxLength: 255,
                        required: true,
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                    }
                },
                {
                    name: 'last_name', align: 'center', text: 'Apellidos', type: 'input', options: {
                        maxLength: 255,
                        required: true,
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                    }
                },
                {
                    name: 'email', align: 'center', text: 'Email', type: 'input', options: {
                        maxLength: 150,
                        type: 'email',
                        required: true,
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                        placeholder: '@',
                    }
                },
                {
                    name: 'phone', align: 'center', text: 'Telefono', type: 'input', options: {
                        maxLength: 10,
                        required: true,
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                        placeholder: 'Télefono a 10 digitos',
                        required: false,
                    }
                },
                {
                    name: 'note', align: 'center', text: 'Comentario', type: 'input', options: {
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: ''
                    },
                    hideOnTable: true
                },
                {
                    name: 'tax_document', align: 'center', text: 'Cédula Fiscal', type: 'file', options: {
                        text: "Cédula Fiscal",
                        smallColor: 'text-dark font-weight-bold',
                        accept: "application/pdf",
                        required: false
                    },
                    onChange: ({ e, setStateDynamic, stateDynamic }) => {
                        if (e.currentTarget.files.length === 0) {
                            setStateDynamic({
                                ...stateDynamic,
                                tax_document: ''
                            })
                        } else {
                            let files = e.currentTarget.files
                            let selectedFile = files[0]
                            let reader = new FileReader()
                            reader.onload = event => {
                                setStateDynamic({
                                    ...stateDynamic,
                                    tax_document: files
                                })
                            }
                            reader.readAsDataURL(selectedFile)
                        }
                    },
                    render: r => {
                        return r.tax_document == "" ? 'Sin Doc' :
                            <a href={Helpers.config.resourcesUrl({ url: '/storage/' + r.tax_document })} target="_blank"><i class="fa fa-2x fa-file"></i></a>
                    },
                    hideOnTable: false,
                },
            ]}
        />
    </>
}

Customers.defaultProps = {
    onlyButton: false,
    btnNewOptions: {},
    afterInsertUpdate: _ => { }
}

export default Customers;