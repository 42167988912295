import React, { useEffect, useState } from 'react';
import MaintenanceCustom from '@views/Maintenance/MaintenanceCustom'
import { toast } from 'react-toastify'
import Helpers from '@components/Helpers/Helpers';
import Controllers from '@components/Controllers/Controllers';
import { SwitchToggle, Select, Input, FormCustom } from '@components/Form/Form';
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/esm/Card';
import ReactPlaceholder from 'react-placeholder'
import { Bar } from 'react-chartjs-2';

/**
 * 0.5.1
 * @author rebootproject.mx
 * @returns 
 */
const DashboardGlobal = _ => {

    const [dates, setDates] = useState({
        p_date_start: Helpers.date.get({ format: '%Y-%m-%d', addDays: -7 }),
        p_date_end: Helpers.date.get({ format: '%Y-%m-%d' }),
    })

    const [saleDaily, setSaleDaily] = useState({})
    const [saleTotal, setSaleTotal] = useState({})
    const [saleWorker, setSaleWorker] = useState([])
    const [saleProduct, setSaleProduct] = useState([])
    const [saleAverage, setSaleAverage] = useState({})
    const [ready, setReady] = useState(false)

    let totalSaleAverage = 0;
    let totalSale = 0;
    let totalDailySale = 0;

    useEffect(_ => {
        if (!ready) {
            Promise.all([
                Controllers.dashboardGlobal.get_sale_daily(),
                Controllers.dashboardGlobal.get_sale_total(dates),
                Controllers.dashboardGlobal.get_sale_worker(dates),
                Controllers.dashboardGlobal.get_sale_product(dates),
                Controllers.dashboardGlobal.get_sale_average(dates),
            ]).then(res => {
                setSaleDaily(res[0].data)
                setSaleTotal(res[1].data)
                setSaleWorker(res[2].data)
                setSaleProduct(res[3].data)
                setSaleAverage(res[4].data)
                setReady(true)
            })
        }
    }, [dates, ready])


    const chartWorker = _ => {
        let workers = []
        let total = []
        saleWorker?.forEach(s => {
            workers.push(s.name_worker + ' ' + s.last_name_worker)
            total.push(s.total)
        })
        return {
            labels: workers,
            datasets: [
                {
                    label: 'Ventas',
                    data: total,
                    backgroundColor: '#F26122',
                }
            ],
        }
    }

    const totalWorkerSales = _ => {
        let total = 0
        saleWorker?.forEach(s => {
            total += parseFloat(s.total)
        })
        return total;
    }

    const workerSaleTop = _ => {
        let workerTop = {
            name_worker: '',
            total: ''
        }
        if (saleWorker?.find(s => s)) {
            let w = saleWorker?.find(s => s)
            workerTop = {
                name_worker: w.name_worker + ' ' + w.last_name_worker,
                total: w.total
            }

        }
        return workerTop
    }

    const chartProduct = _ => {
        let products = []
        let total = []
        saleProduct?.forEach(s => {
            products.push(s.description_product)
            total.push(s.total)
        })
        return {
            labels: products,
            datasets: [
                {
                    label: 'Cantidad Vendidas',
                    data: total,
                    backgroundColor: '#42BDA1',
                },
            ],
        }
    }

    const totalProductSale = _ => {
        let total = 0
        saleProduct?.forEach(s => {
            total += parseFloat(s.total)
        })
        return total
    }

    const productSaleTop = _ => {
        let productTop = {
            description_product: '',
            total: ''
        }
        if (saleProduct?.find(s => s)) {
            productTop = saleProduct?.find(s => s)
            productTop.total = parseInt(productTop.total)
        }
        return productTop
    }

    const options = {
        indexAxis: 'y',
    };

    return <div className="mt-4">
        <div className="form-row">
            <div className="col-12 mb-4">
                <Card>
                    <Card.Body>
                        <small className="font-weight-bold">Filtar por Fechas</small>
                        <FormCustom viewSubmit={false} onSubmit={({ hasError }) => {
                            if (!hasError) {
                                setReady(false)
                            }
                        }}>
                            <Input
                                type="date"
                                text="Fecha Inicio"
                                classNameParent="col-md-6"
                                value={dates.p_date_start}
                                required
                                onChange={e => {
                                    setDates({ ...dates, p_date_start: e.currentTarget.value })
                                }}
                                size=""
                                disabled={!ready}
                            />
                            <Input
                                type="date"
                                text="Fecha Fin"
                                classNameParent="col-md-6"
                                value={dates.p_date_end}
                                onChange={e => {
                                    setDates({ ...dates, p_date_end: e.currentTarget.value })
                                }}
                                required
                                append={[
                                    <Button type="submit" disabled={!ready} style={{ borderRadius: 10 }}>
                                        {!ready ? <i className="fa fa-circle-notch fa-spin"></i> : <><i className="fa fa-search mr-1"></i> Filtrar</>}
                                    </Button>
                                ]}
                                size=""
                                disabled={!ready}
                            />
                        </FormCustom>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-6 col-lg-3 mb-4">
                <Card>
                    <Card.Body>
                        <ReactPlaceholder ready={ready} rows={4} showLoadingAnimation>
                            <small className="font-weight-bold text-right">PROMEDIO DE VENTAS DIARIO</small>
                            {(saleAverage?.length > 0) ? saleAverage?.map(r => {
                                totalSaleAverage += parseFloat(r.total)
                                return <div className="text-right">
                                    <strong>#{r.name}</strong> - {Helpers.number.withCommas({ number: parseFloat(r.total) })}
                                </div>
                            }) : ''}
                            <div className="mt-2">
                                <p className="text-primary text-right"><strong style={{ color: '#F26122' }}>Promedio diario total $ {Helpers.number.withCommas({ number: totalSaleAverage })}</strong></p>
                                <div style={{
                                    background: 'linear-gradient(118deg, #F26122, #F9870C)',
                                    width: '100%',
                                    height: 4,
                                    borderRadius: 5
                                }}></div>
                            </div>
                        </ReactPlaceholder>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-6 col-lg-3 mb-4">
                <Card>
                    <Card.Body>
                        <ReactPlaceholder ready={ready} rows={4} showLoadingAnimation>
                            <small className="font-weight-bold text-right">VENTA TOTAL</small>
                            <div className="mt-2">
                                {(saleTotal?.length > 0) ? saleTotal?.map(r => {
                                    totalSale += parseFloat(r.total)
                                    return <div className="text-right">
                                        {/* <p class="d-none">{totalSale += parseFloat(r.total)}</p> */}
                                        <strong>#{r.name}</strong> - {Helpers.number.withCommas({ number: parseFloat(r.total) })}
                                    </div>
                                }) : ''}
                                <p className="text-primary text-right"><strong style={{ color: '#774EF6' }}>Gran Total $ {Helpers.number.withCommas({ number: totalSale })}</strong></p>
                                <div style={{
                                    background: 'linear-gradient(118deg, #774EF6, #0BBDF8)',
                                    width: '100%',
                                    height: 4,
                                    borderRadius: 5
                                }}></div>
                            </div>
                        </ReactPlaceholder>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-6 col-lg-3 mb-4">
                <Card>
                    <Card.Body>
                        <ReactPlaceholder ready={ready} rows={4} showLoadingAnimation>
                            <small className="font-weight-bold text-right">VENTA DIARIA</small>
                            <div className="mt-2">
                                {(saleDaily?.length > 0) ? saleDaily?.map(r => {
                                    totalDailySale += parseFloat(r.total)
                                    return <div className="text-right">
                                        <strong>#{r.name}</strong> - {Helpers.number.withCommas({ number: parseFloat(r.total) })}
                                    </div>
                                }) : ''}
                                <p className="text-primary text-right"><strong style={{ color: '#BB02CE' }}>Total del día $ {Helpers.number.withCommas({ number: totalDailySale })}</strong></p>
                                <div style={{
                                    background: 'linear-gradient(118deg, #BB02CE, #8F6FF0)',
                                    width: '100%',
                                    height: 4,
                                    borderRadius: 5
                                }}></div>
                            </div>
                        </ReactPlaceholder>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-6 col-lg-3 mb-4">
                <Card>
                    <Card.Body>
                        <ReactPlaceholder ready={ready} rows={4} showLoadingAnimation>
                            <small className="font-weight-bold">Reporte Excel</small>
                            <div className="mt-2">
                                <a class="btn btn-success btn-lg" href={'/dashboard_global/export/' + dates.p_date_start + '/' + dates.p_date_end + '?jwt=' + Helpers.localStorage.getJWT()}><i class="fas fa-file-excel"></i> Descargar Excel</a>
                            </div>
                        </ReactPlaceholder>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-md-12 mb-4">
                <Card>
                    <Card.Header>Ventas por Empleado</Card.Header>
                    <Card.Body>
                        <div className="form-row">
                            <div className="col-md-3">
                                <div className="d-flex flex-column">
                                    <div className="mb-4">
                                        <small>Cantidad de Empleados</small>
                                        <strong className="text-dark">
                                            <h3 className="mb-0">
                                                <ReactPlaceholder ready={ready} rows={1} showLoadingAnimation>
                                                    {saleWorker.length}
                                                </ReactPlaceholder>
                                            </h3>
                                        </strong>
                                    </div>
                                    <div className="mb-4">
                                        <small>Total de Ventas</small>
                                        <strong className="text-dark">
                                            <h3 className="mb-0">
                                                <ReactPlaceholder ready={ready} rows={1} showLoadingAnimation>
                                                    $ {Helpers.number.withCommas({ number: parseFloat(totalWorkerSales()) })}
                                                </ReactPlaceholder>
                                            </h3>
                                        </strong>
                                    </div>
                                    <div>
                                        <small>Con Más Ventas</small>
                                        <strong className="text-dark">

                                            <ReactPlaceholder ready={ready} rows={2} showLoadingAnimation>
                                                <h3 className="mb-0">
                                                    $ {Helpers.number.withCommas({ number: parseFloat(workerSaleTop().total) })}
                                                </h3>
                                                <small className="text-primary">{workerSaleTop().name_worker}</small>
                                            </ReactPlaceholder>
                                        </strong>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <ReactPlaceholder ready={ready} rows={4} showLoadingAnimation>
                                    <Bar data={chartWorker()} options={options} />
                                </ReactPlaceholder>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-md-12">
                <Card>
                    <Card.Header>Ventas por Producto</Card.Header>
                    <Card.Body>
                        <div className="form-row">
                            <div className="col-md-3">
                                <div className="d-flex flex-column">
                                    <div className="mb-4">
                                        <small>Cantidad de Productos</small>
                                        <strong className="text-dark">
                                            <h3 className="mb-0">
                                                <ReactPlaceholder ready={ready} rows={1} showLoadingAnimation>
                                                    {saleProduct.length}
                                                </ReactPlaceholder>
                                            </h3>
                                        </strong>
                                    </div>
                                    <div className="mb-4">
                                        <small>Cantidad Total</small>
                                        <strong className="text-dark">
                                            <h3 className="mb-0">
                                                <ReactPlaceholder ready={ready} rows={1} showLoadingAnimation>
                                                    {totalProductSale()}
                                                </ReactPlaceholder>
                                            </h3>
                                        </strong>
                                    </div>
                                    <div>
                                        <small>Más Vendido</small>
                                        <strong className="text-dark">

                                            <ReactPlaceholder ready={ready} rows={2} showLoadingAnimation>
                                                <h3 className="mb-0">
                                                    {productSaleTop().total}
                                                </h3>
                                                <small className="text-primary">{productSaleTop().description_product}</small>
                                            </ReactPlaceholder>
                                        </strong>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <ReactPlaceholder ready={ready} rows={4} showLoadingAnimation>
                                    <Bar data={chartProduct()} options={options} />
                                </ReactPlaceholder>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
    </div>
}

export default DashboardGlobal;