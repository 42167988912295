import React from 'react';
import Maintenance from '@components/Maintenance/Maintenance'
import Button from 'react-bootstrap/Button';
import Helpers from '@components/Helpers/Helpers';

const MaintenanceCustom = ({ prependCard, ...props }) => {
    return <Maintenance
        showCardHeader={false}
        modalTitleCustom
        modalTitleRender={({ title, closeModal }) => {
            return <>
                <img src={Helpers.config.resourcesUrl({ url: '/images/dashboard/brand-dashboard.png' })} className="img-fluid" style={{
                    maxWidth: 150
                }} />
                <div className="d-flex justify-content-center align-self-center">
                    {title}
                </div>
                <div className="text-right align-self-center">
                    <svg className="cur-pointer" onClick={closeModal} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
            </>
        }}
        btnNewColor="secondary"
        beforeCard={({ handleGet, btnNewColor, title, setReload }) => <div className="page-wrapper-top d-flex flex-column flex-lg-row justify-content-between pl-0 pr-0">
            <div>
                <h2 className="page-wrapper-title">{title}</h2>
            </div>
            <div>
                {prependCard({ setReload })}
                <Button className="btn-circle mb-2" variant={btnNewColor} onClick={handleGet}>
                    <i className="fa fa-plus"></i>
                </Button>
            </div>
        </div>}
        {...props}
        optionsView={{
            ...props.optionsView,
            formCustomOptions: ({ data }) => {
                return {
                    buttonOptions: {
                        variant: 'primary',
                        block: false,
                        size: '',
                        icon: 'fa fa-save',
                    },
                    buttonClassNameParent: 'col-12 d-flex justify-content-end',
                    ...(props?.optionsView?.formCustomOptions ? props.optionsView.formCustomOptions({ data }) : {}),
                }
            }
        }}
    />
}

MaintenanceCustom.defaultProps = {
    prependCard: _ => '',
    optionsView: {}
}

export default MaintenanceCustom