import React, { useState } from 'react'
import Helpers from '@components/Helpers/Helpers';
import Controllers from '@components/Controllers/Controllers';
import { Input, Select } from '@components/Form/Form';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal';

/**
 * Labels
 * @version 0.9.82
 */
const Labels = _ => {
    const [stateDynamic, setStateDynamic] = useState({ products: [] })
    const [execValidation, setExecValidation] = useState(true)
    const [state, setState] = useState({})
    const [printerState, setPrinterState] = useState({ zpl: '', printer: null, printerStatus: null, error: null })
    const [stateModal, setModalState] = useState({ confirm: false })

    Controllers.product.get_product().then((res) => {
        state.product = res.data;
    })

    // Initializing Zebra printer
    window.BrowserPrint.getDefaultDevice("printer", function (device) {
        console.log("BrowserPrint Init");
        printerState.printer = device;
        printerState.printer.read((_) => { printerState.printerStatus = "Device Ready"; }, (error) => { printerState.printerStatus = error });
    }, function (error) {
        printerState.error = error;
    })

    const addProduct = ({ stateDynamic, setStateDynamic, product }) => {

        let discountPrice;

        if (product.discount_type_document_det == 'SIN_DESCUENTO') {
            discountPrice = product.price_product;
        } else if (product.discount_type_document_det == 'PRICE') {
            discountPrice = product.price_product - product.discount_document_det;
        } else if (product.discount_type_document_det == 'PERCENTAGE') {
            discountPrice = product.price_product - (product.price_product * (product.discount_document_det / 100));
        }

        setStateDynamic({
            ...stateDynamic,
            products: [
                ...stateDynamic.products,
                {
                    ...product,
                    subtotal: 0,
                    id_document_det: '',
                    price_document_det: product.price_product,
                    discount_type_document_det: product.discount_type_document_det,
                    qty_document_det: 1,
                    discount_document_det: product.discount_document_det,
                    discount_price_document_det: discountPrice,
                    id_product: product.id_product
                }
            ],
        })
    }

    const sendToPrinter = () => {
        console.log("Send to printer");

        let y = 1;
        let _zpl = '';
        stateDynamic.products.map((item) => {
            for (let x = 0; x < item.qty_document_det; x++) {
                if (y > 3) y = 1;
                if (y == 1) {
                    _zpl += `^XA^LS2^CFA,18^FO90,30^FD${item.id_item_global_product}^FS^CFA,24^FO75,65^FD$${Helpers.number.withCommas({ number: parseFloat(item.price_product) })}^FS`;
                    y++;
                } else if (y == 2) {
                    _zpl += `^CFA,18^FO330,30^FD${item.id_item_global_product}^FS^CFA,24^FO320,65^FD$${Helpers.number.withCommas({ number: parseFloat(item.price_product) })}^FS`;
                    y++;
                } else if (y == 3) {
                    _zpl += `^CFA,18^FO570,30^FD${item.id_item_global_product}^FS^CFA,24^FO565,65^FD$${Helpers.number.withCommas({ number: parseFloat(item.price_product) })}^FS^XZ`;
                    y++;
                }
            }
        });

        if (y < 4) {
            _zpl += '^XZ';
        }

        printerState.zpl = _zpl;

        printerState.printer.send(_zpl, undefined, (error) => { printerState.error = `Error: ${error}` });
    }

    return <>
        <Modal show={stateModal.confirm} backdrop="static" centered>
            <Modal.Body className="text-center">
                <i className={'fa fa-print text-warning fa-5x mt-4 mb-4'}></i>

                <h3><strong>Enviar trabajo de impresión</strong></h3>
                <p>¿Estas seguro que deseas enviar este juego de etiquetas a imprimir?</p>
                <div className="mt-4">
                    <Button variant={`danger mr-2`} onClick={_ => {
                        sendToPrinter()
                        setModalState({ confirm: false })
                    }}>
                        Enviar Impresión
                    </Button>
                    <Button variant="outline-dark" onClick={_ => setModalState({ confirm: false })}>Cancelar</Button>
                </div>
            </Modal.Body>
        </Modal>
        <div className="page-wrapper-top d-flex flex-column flex-lg-row justify-content-between pl-0 pr-0">
            <h2 class="page-wrapper-title"><i class="fa fa-print m2"></i> Etiquetas de precio</h2>
            <hr className="mb-2 mt-2" />
        </div>

        <div class="card">
            <div class="row">
                <div className="col-md-6 p-4">
                    <label>Buscar Producto</label>
                    <Select
                        autoFocus
                        smallColor="text-dark font-weight-bold"
                        classNameParent=""
                        size=""
                        options={state?.product ? state.product.map(p => {//TODO:: OJOTE AQUI
                            return {
                                label: `${p.description_product} - ${p.upc_product} - ${p.id_item_global_product} - ${p.key_product}`,
                                value: p.id_product,
                                product: p,
                                isDisabled: isNaN(parseFloat(p.price_product)) || stateDynamic?.products?.some(pp => parseInt(pp.id_product) === parseInt(p.id_product))
                            }
                        }) : []}

                        onFocus={_ => setExecValidation(false)}
                        onBlur={_ => setExecValidation(true)}
                        value={''}
                        onChange={e => {
                            addProduct({ setStateDynamic, stateDynamic, product: e.product })
                        }}
                        placeholder="Buscar por Clave, UPC, Item Global, Descripción"
                    />
                </div>
                <div class="col-6 p-4">
                    <p class="lead">Impresora Zebra Modelo: {printerState.printer?.name} <br />Conexión: {printerState.printer?.connection} <br />Estado: {printerState.printerStatus} <br /> {(printerState.error == "" || printerState.error == null) ? "" : "Error:" + printerState.error}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div className="table-responsive">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th style={{ minWidth: 100 }}>Producto</th>
                                    <th style={{ minWidth: 100 }}>Precio</th>
                                    <th style={{ minWidth: 150, maxWidth: 150, width: 150 }}>Cantidad</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {React.Children.toArray(stateDynamic?.products?.map((c, i) => {

                                    return <tr>
                                        <td>
                                            <strong>{c.description_product}</strong><br />
                                            <small>Item: {c.id_item_global_product}</small>
                                        </td>
                                        <td className="text-center">
                                            <strong className="text-primary">$ {Helpers.number.withCommas({ number: parseFloat(c.price_document_det) })}</strong>
                                        </td>
                                        <td className="text-center">
                                            <Input
                                                min="1"
                                                step="1"
                                                type="number"
                                                className="text-center"
                                                value={c.qty_document_det}
                                                onChange={e => {
                                                    setStateDynamic({
                                                        ...stateDynamic,
                                                        products: stateDynamic.products.map((pp, ii) => {
                                                            if (i === ii) {
                                                                pp.qty_document_det = e.currentTarget.value
                                                            }

                                                            return pp
                                                        })
                                                    })
                                                }}
                                                required
                                            />

                                        </td>

                                        <td className="text-center">
                                            <Button variant="danger" size="xs" onClick={_ => {
                                                setStateDynamic({
                                                    ...stateDynamic,
                                                    products: stateDynamic.products.filter((pp, ii) => {
                                                        return i !== ii
                                                    })
                                                })
                                            }}>
                                                <i className="fa fa-trash"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                }))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        {/* <div class="card">{printerState.zpl}</div> */}
        <Button variant="primary" onClick={_ => setModalState({ confirm: true })} style={{ position: "absolute", bottom: "16px", right: '16px' }}>
            <i class="fa fa-print m2"></i> Imprimir
        </Button>
    </>
}

Labels.defaultProps = {
    showFirstLevel: true
}

export default Labels;