import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import {Input, SwitchToggle} from '@components/Form/Form'
import TagsItemsChildren from '@views/TagsItems/TagsItemsChildren';

const TagsItemsParent = ({ parent, idx, changeDescriptionParent, changeIconParent, changeEnabledParent, changeDescriptionChildren, changeHrefChildren, changeEnabledChildren, changeShowChildren, addChildren, deleteParent, deleteChildren }) => {
    return <>
        <Card className="card-with-border">
            <Card.Header className="justify-content-between">
                <Accordion.Toggle as={Button} variant="link" eventKey={idx}>
                    {parent.parent.description.trim() ? parent.parent.description : <div className="text text-warning">Sin Nombre</div>}
                </Accordion.Toggle>
                <Button variant="danger" size="sm" onClick={deleteParent}>
                    <i className="fa fa-trash"></i>
                </Button>
            </Card.Header>
            <Accordion.Collapse eventKey={idx}>
                <Card.Body>
                    <div className="form-row mb-3">
                        <Input
                            type="text"
                            text="Descripción"
                            placeholder="Descripción"
                            classNameParent="col-md-6 mb-2"
                            icon="far fa-comment-alt"
                            value={parent.parent.description}
                            onChange={changeDescriptionParent}
                            maxLength={100}
                        />
                        <Input
                            type="text"
                            text="Ícono"
                            placeholder="Ícono"
                            classNameParent="col-md-6 mb-2"
                            icon="far fa-comment-alt"
                            value={parent.parent.icon}
                            onChange={changeIconParent}
                        />
                        <SwitchToggle
                            id={`enabled-parent-${idx}`}
                            checked={parseInt(parent.parent.enabled) === 1}
                            onChange={changeEnabledParent}
                        />
                    </div>
                    <Accordion>
                        <Card>
                            <Card.Header className="card-with-border justify-content-between">
                                <span>Listado de Items</span>
                                <Button size="sm" variant="dark" onClick={addChildren}>
                                    <i className="fa fa-plus"></i>
                                </Button>
                            </Card.Header>
                        </Card>
                        {React.Children.toArray(parent.childrens.map((ch, i) => {
                            return <>
                                <TagsItemsChildren children={ch} idxParent={idx} idx={i + 1} 
                                    changeDescriptionChildren={e => {
                                        changeDescriptionChildren({ e, idxChildren: i })
                                    }}
                                    changeHrefChildren={e => {
                                        changeHrefChildren({ e, idxChildren: i })
                                    }}
                                    changeEnabledChildren={e => {
                                        changeEnabledChildren({ e, idxChildren: i })
                                    }}
                                    changeShowChildren={e => {
                                        changeShowChildren({ e, idxChildren: i })
                                    }}
                                    deleteChildren={_ => {
                                        deleteChildren({ idxChildren: i, exec: ch.description !== '', idTagsItems: ch.id_tags_items })
                                    }}
                                />
                            </>
                        }))}
                    </Accordion>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    </>
}

export default TagsItemsParent