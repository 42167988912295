import React from 'react';
import MaintenanceCustom from '@views/Maintenance/MaintenanceCustom'
import Button from 'react-bootstrap/Button'
import Helpers from '@components/Helpers/Helpers';

import { toast } from 'react-toastify'
import Controllers from '@components/Controllers/Controllers';
import { CheckBox } from '@components/Form/Form'
import { SwitchToggle } from '@components/Form/Form'

/**
 * SaleDetail
 * @version 0.4.3
 */
const SaleDetail = ({ showFirstLevel }) => {
    return <>
        <MaintenanceCustom
            prependCard={({ setReload }) => <>
                <a href={Helpers.config.resourcesUrl({
                    url: '/sale_detail/get_csv?jwt=' + Helpers.localStorage.getJWT()
                })} download="detalle_de_ventas_diaria" className="mr-2 btn btn-primary">Descargar Reporte</a>
            </>}
            title={<>
                <i className="fa fa-file-invoice-dollar mr-2"></i> Detalle diario de ventas
            </>}
            removeId
            removeEnabled
            removeDescription
            actionsCustom
            actions={({ updateButton, deleteButton, data }) => {
                return ''
            }}
            namePlural="Movimientos" nameSingle="Movimiento" separator="El" module="sale_detail"
            tableFieldsAdd={[
                {
                    name: 'id_document', align: 'center', text: 'Ticket',
                    hideOnForm: true
                },
                {
                    name: 'date_document', align: 'center', text: 'Fecha de venta',
                    hideOnForm: true
                },
                {
                    align: 'center', text: 'Vendedor', render: r => {
                        return `${r.name_worker + ' ' + r.last_name_worker}`
                    },
                    hideOnForm: true
                },
                {
                    name: 'id_item_global_product', align: 'center', text: 'Item Global',
                    hideOnForm: true
                },
                {
                    name: 'key_product', align: 'center', text: 'SKU',
                    hideOnForm: false
                },
                {
                    name: 'name', align: 'center', text: 'Almacen',
                    hideOnForm: false
                },
                {
                    name: 'description_format_pay', align: 'center', text: 'Pago',
                    hideOnForm: true
                },
                {
                    name: 'qty_document_det', align: 'center', text: 'Cantidad',
                    hideOnForm: false
                },
                {
                    name: 'price_document_det', align: 'center', text: 'Precio',
                    hideOnForm: false
                },
                {
                    name: 'discount_document_det', align: 'center', text: 'Descuento',
                    hideOnForm: true
                },
                {
                    name: 'discount_price_document_det', align: 'center', text: 'Total',
                    hideOnForm: true
                },
                {
                    name: 'discount_type_document_det', align: 'center', text: 'Tipo de descuento',
                    hideOnForm: true
                },
            ]}
            optionsView={
                {
                    removeEnabled: true,
                    removeDescription: true,
                    removeEnabled: true,
                }
            }
        />
    </>
}

SaleDetail.defaultProps = {
    showFirstLevel: true,
    onlyButton: true,
}

export default SaleDetail;